import React from 'react'
import PropTypes from 'prop-types'

import styles from './TaskAction.module.scss'

const TaskAction = ({ renderButton, url }) => (
  <div className={styles['task-action']}>
    {url ? (
      <a href={url} download target='_blank' rel='noopener noreferrer'>
        {renderButton()}
      </a>
    ) : (
      renderButton(true)
    )}
  </div>
)

TaskAction.propTypes = {
  renderButton: PropTypes.func.isRequired,
  url: PropTypes.string,
}

export default TaskAction
