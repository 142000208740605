import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import { Text } from '@plurall/elo-beta'

import hacParams from 'utils/hacParams'

import CheckActivity from './CheckActivity'

import Essay from './Essay'
import MultipleChoice from './MultipleChoice'

import styles from './SubTask.module.scss'

const SubTask = ({ answers, onClick, subtask, taskId }) => {
  const { queryParameters } = hacParams()
  return (
    <Route
      render={({
        match: {
          params: { lessonId, materialId },
        },
      }) => (
        <React.Fragment key={subtask.id}>
          <div className={styles['subtask-holder']}>
            <div className={styles['exercise-number']}>
              <Text element='span' semibold secondary>
                {subtask.title}
              </Text>
            </div>
            {subtask.options ? (
              <div className={styles['multiple-choice-holder']}>
                <MultipleChoice
                  answers={answers}
                  onClick={onClick}
                  subtask={subtask}
                  taskId={taskId}
                />
              </div>
            ) : (
              <div className={styles['essay-holder']}>
                <Essay {...subtask} />
              </div>
            )}

            <CheckActivity
              activityPathname={`/material/${materialId}/aula/${lessonId}/tarefa/${taskId}/exercicio/${subtask.id}${queryParameters}&resposta_rapida=true`}
              url={subtask.url}
            />
          </div>
        </React.Fragment>
      )}
    />
  )
}

SubTask.propTypes = {
  answers: PropTypes.array,
  onClick: PropTypes.func,
  subtask: PropTypes.object.isRequired,
}

export default SubTask
