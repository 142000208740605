import React from 'react'
import PropTypes from 'prop-types'

import { Card } from '@plurall/elo-beta'

import StatusInWords from 'App/components/StatusInWords'

import styles from './TaskStatus.module.scss'

const TaskStatus = ({ officialAnswerInWords, statusInWords }) => (
  <div className={styles['activity-status']}>
    <Card>
      <div className={styles['status-container']}>
        <StatusInWords justifyContent='center' status={statusInWords} />
      </div>
    </Card>
    {officialAnswerInWords && (
      <Card>
        <div className={styles['status-container']}>
          <StatusInWords justifyContent='center' status={officialAnswerInWords} />
        </div>
      </Card>
    )}
  </div>
)

TaskStatus.propTypes = {
  officialAnswerInWords: PropTypes.string,
  statusInWords: PropTypes.string,
}

export default TaskStatus
