import React from 'react'
import PropTypes from 'prop-types'

import { Text, Heading, Card } from '@plurall/elo-beta'

import ExerciseCard from './ExerciseCard'
import styles from './Exercises.module.scss'

const Exercises = ({ activityStatus, exercises }) => (
  <section className={styles.exercises}>
    <Card secondary>
      <div className={styles['card-wrapper']}>
        <Heading secondary element='h4' noParagraph>
          {exercises[0].title}
        </Heading>

        {exercises.map(({ description, subtasks, title }) => (
          <React.Fragment key={`${description}-${title}`}>
            {description && (
              <div className={styles['slug-wrapper']} key={description}>
                <Text secondary>{description}</Text>
              </div>
            )}

            <div className={styles['exercises-holder']}>
              {subtasks.map(subtask => (
                <ExerciseCard
                  activityStatus={activityStatus}
                  exerciseStatus={subtask.status || 'blank'}
                  id={subtask.id}
                  key={subtask.id}
                  selectedOption={subtask.userAnswer}
                  sequence={subtask.title}
                />
              ))}
            </div>
          </React.Fragment>
        ))}
      </div>
    </Card>
  </section>
)

Exercises.propTypes = {
  activityStatus: PropTypes.oneOf(['available', 'finished']).isRequired,
  exercises: PropTypes.array.isRequired,
}

export default Exercises
