import React, { createContext, useState, useEffect, useContext } from 'react'
import Client from 'utils/client'
import PropTypes from 'prop-types'
import { getToken } from 'utils/token'

const UserContext = createContext({})

export const UserProvider = ({ children }) => {
  const client = new Client({ accept: 'vnd.plurall.api.v2+json' })

  const [user, setUser] = useState()

  useEffect(() => {
    const fetchProfile = async () => {
      await client.getProfile().then(result => {
        setUser(result)
      })
    }
    if (getToken()) {
      fetchProfile()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
}

export const useUserContext = () => {
  const context = useContext(UserContext)

  return context
}

UserProvider.propTypes = {
  children: PropTypes.node,
}
