import React from 'react'
import ErrorMessage from 'App/components/ErrorMessage/ErrorMessage'
import queryString from 'query-string'
import { clearToken } from 'utils/token'
import PropTypes from 'prop-types'

class Error extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  }

  state = {}

  componentDidMount = () => {
    const { status, message } = queryString.parse(this.props.location.search)
    if (!status || !message) {
      window.location.href = '/'
    }

    this.setState({
      msg:
        message !== 'undefined'
          ? message
          : 'Ops! Ocorreu um erro. Caso o erro persista, entre em contato com o suporte.',
    })

    if (Number(status) === 401) {
      clearToken()
      window.location.reload()
    }
  }

  render() {
    return <ErrorMessage title={this.state.msg} padding='10vh 0 0 0' />
  }
}

export default Error
