/* eslint-disable quotes */
import * as Sentry from '@sentry/react'

import { getToken } from './token'

const {
  REACT_APP_NODE_ENV,
  REACT_APP_SENTRY_CLIENT,
  REACT_APP_SENTRY_ERROR_RATE,
  REACT_APP_SENTRY_TRACES_RATE,
} = process.env
const errorBlacklist = [
  'Failed to fetch',
  'GraphQL error: Authorization token',
  'Load failed',
  'Network Error',
  'NetworkError',
  'Request failed with status code',
  'gtag',
  'Java exception was raised during method invocation',
  'Response not successful: Received status code 403',
  'Firebase: Error (auth/network-request-failed)',
  'TimeoutError: Transaction timed out due to inactivity.',
  'UnknownError: Connection to Indexed Database server lost. Refresh the page to try again',
  'timeout of 0ms exceeded',
  'ResizeObserver loop completed with undelivered notifications.',
  'getGraphQLActivesYears: usuário sem token.',
  "Cannot read properties of null (reading 'restClient')",
  "null is not an object (evaluating 'a.getPlurallClient().restClient')",
]

let environment = REACT_APP_NODE_ENV
if (environment === 'pull_request' || environment === 'local') {
  environment = 'staging'
}

const beforeSendFiltering = (event, hint) => {
  if (hint?.originalException) {
    const message = hint.originalException.message || hint.originalException
    const foundBlackListedTerm = !!errorBlacklist.filter(error =>
      message.toLowerCase().includes(error.toLowerCase()),
    ).length
    if (foundBlackListedTerm) {
      return null
    }
  }
  return event
}

const setUser = () => {
  const userLocal = localStorage.getItem(`_plurall_user_${getToken()}`)
  if (userLocal) {
    const user = JSON.parse(userLocal)
    Sentry.setUser({
      email: user.mail,
      plurall_id: user.plurall_id,
      somos_id: user.somos_id,
    })
  }
}

const routes = [
  { path: '/materiais/:materialId' },
  { path: '/materiais' },
  { path: '/materiais/:materialId/aula' },
  { path: '/materiais/:materialId/aula/:lessonId' },
  { path: '/materiais/:materialId/aula/:lessonId/resposta-rapida' },
  { path: '/materiais/:materialId/aula/:lessonId/tarefa' },
  { path: '/materiais/:materialId/aula/:lessonId/tarefa/:taskId' },
  { path: '/materiais/:materialId/aula/:lessonId/tarefa/:taskId/exercicio' },
  { path: '/materiais/:materialId/aula/:lessonId/tarefa/:taskId/exercicio/:exercicioId' },
  { path: '/exercicio' },
  { path: '/exercicio/:id' },
  { path: '/aula' },
  { path: '/aula/:lessonId' },
  { path: '/tarefa' },
  { path: '/tarefa/:taskId' },
  { path: '/leitura' },
  { path: '/video' },
  { path: '/material-de-apoio' },
  { path: '/' },
]

export function initSentry(history) {
  if (REACT_APP_SENTRY_CLIENT) {
    Sentry.init({
      dsn: REACT_APP_SENTRY_CLIENT,
      environment,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routes),
        }),
      ],
      sampleRate: +REACT_APP_SENTRY_ERROR_RATE,
      tracesSampleRate: +REACT_APP_SENTRY_TRACES_RATE,
      beforeSend: beforeSendFiltering,
      ignoreErrors: [
        'Failed to fetch',
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
      ],
    })
    setUser()
  }
}
