import React from 'react'
import PropTypes from 'prop-types'

import { Text } from '@plurall/elo-beta'

import styles from './Tag.module.scss'

const Tag = ({ className, text }) => (
  <div className={styles[className]}>
    <Text variant='brand' alignment='center'>
      {text}
    </Text>
  </div>
)

Tag.propTypes = {
  className: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default Tag
