import queryString from 'query-string'

const hacParams = () => {
  const { filter_context } = queryString.parse(window.location.search) // eslint-disable-line camelcase
  const queryParams = queryString.parse(window.location.search)
  const params = {
    filter_context,
    rootPathname: '/material',
  }

  if (queryParams) {
    params.queryParameters = `?${queryString.stringify(queryParams)}`
  }

  return params
}

export default hacParams
