import React from 'react'
import PropTypes from 'prop-types'
import { Link, Route } from 'react-router-dom'

import { Card, Text } from '@plurall/elo-beta'

import MediaContent from 'App/components/MediaContent'

import ReactPlayer from 'react-player'

import { trackVideoToggle } from 'utils/tracker'
import { trackingEventNames } from 'App/constants/tracking'
import getSupportMaterialType from 'utils/getSupportMaterialType'
import hacParams from 'utils/hacParams'

import { optionProps } from './ExerciseSupport'

import styles from './SupportCard.module.scss'

class SupportCard extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    option: PropTypes.shape(optionProps).isRequired,
    taskName: PropTypes.string.isRequired,
    taskVideo: PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      thumbnail: PropTypes.string,
    }),
    userMessage: PropTypes.object,
  }

  state = {}

  toggleVideo = () => {
    this.setState(
      { isOpen: !this.state.isOpen },
      () =>
        this.state.isOpen &&
        trackVideoToggle({
          key: this.props.option.key,
          params: this.trackingParams,
        }),
    )
  }

  trackDocumentClick = () => {
    window.PLURALL_TRACKER.track(
      trackingEventNames.supportCard.questionReadClick,
      this.trackingParams,
    )
  }

  trackDoubtsClick = () => {
    window.PLURALL_TRACKER.track(
      trackingEventNames.supportCard.questionDoubtsClick,
      this.trackingParams,
    )
  }

  renderCard = () => (
    <Card interactive>
      <div className={styles['support-box-wrapper']} data-test-id={`${this.props.option.key}`}>
        <Text size='t3' semibold variant='brand' element='span'>
          {this.props.option.title}
        </Text>
      </div>
    </Card>
  )

  render() {
    const {
      renderCard,
      trackDocumentClick,
      trackDoubtsClick,
      toggleVideo,
      props: {
        onClick,
        option: { key, redirectUrl, type, url, videoUrl },
        taskName,
        taskVideo,
        userMessage,
      },
      state: { isOpen },
    } = this

    const { doubts, isTextDocument, isVideo } = getSupportMaterialType({ key, type })
    const { queryParameters } = hacParams()

    return (
      <Route
        render={({
          location: { pathname },
          match: {
            params: { materialId },
          },
        }) => {
          this.trackingParams = {
            task_name: taskName,
            material: materialId,
          }

          return (
            <div className={styles['card-wrapper']}>
              {doubts && (
                <a
                  href={redirectUrl}
                  onClick={userMessage ? onClick : trackDoubtsClick}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {renderCard()}
                </a>
              )}

              {isVideo &&
                (userMessage ? (
                  <Link
                    onClick={() => onClick(url)}
                    to={`${pathname}${
                      pathname.endsWith('/') ? '' : '/'
                    }material-de-apoio${queryParameters}`}
                  >
                    {renderCard()}
                  </Link>
                ) : (
                  <div>
                    {key === 'video_external' && taskVideo ? (
                      <MediaContent
                        onMediaClick={() =>
                          window.PLURALL_TRACKER.track(
                            trackingEventNames.supportCard.videoCantoLousaExternalClick,
                          )
                        }
                        thumbnail={taskVideo.thumbnail}
                        title={taskVideo.title}
                        url={taskVideo.url}
                      />
                    ) : (
                      <div className={styles['support-video']} onClick={toggleVideo}>
                        {renderCard()}
                      </div>
                    )}

                    {isOpen && (
                      <div className={styles['video-wrapper']}>
                        {videoUrl?.map((link, index) => (
                          <div className={styles.player}>
                            <ReactPlayer
                              url={link}
                              title={index}
                              controls
                              width='100%'
                              height='100%'
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}

              {isTextDocument && (
                <Link
                  to={`${pathname}${
                    pathname.endsWith('/') ? '' : '/'
                  }material-de-apoio${queryParameters}`}
                  onClick={() => {
                    trackDocumentClick()
                    onClick(url)
                  }}
                >
                  {renderCard()}
                </Link>
              )}
            </div>
          )
        }}
      />
    )
  }
}

export default SupportCard
