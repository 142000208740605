import React from 'react'

const store = {
  taskListTitle: 'Atividades',
  userProfile: {
    name: '',
    email: '',
  },
}

export const TitleContext = React.createContext(store)
