import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Input, ControlGroupItem, ControlGroupHolder } from '@plurall/elo-beta'
import DropdownItem from './DropdownItem'
import styles from './InputDropdownCustom.module.css'

const InputDropdownCustom = props => {
  const {
    categorized,
    isGroupDivider,
    dataTestId,
    disabled,
    label,
    placeholder,
    scrollbarWrapperHeight,
    scrollbarMaxHeight,
    footerText,
    isFooterDisabled,
    footerIcon,
    footerNavigationItem,
    inlineEdit,
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const [items, setItems] = useState([])
  const inputDropdownRef = useRef(null)

  const getInput = () => inputDropdownRef.current?.querySelector('input')

  useEffect(() => {
    const handleDocumentClick = event => {
      if (
        isOpen &&
        inputDropdownRef?.current &&
        !inputDropdownRef.current?.contains(event.target)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('click', handleDocumentClick)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [isOpen])

  useEffect(() => {
    setItems(props.items || [])
  }, [props.items])

  const handleClick = event => {
    const { onClick } = props
    if (disabled) {
      return
    }

    if (onClick) {
      try {
        onClick(event)
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  }

  const handleFocus = () => {
    setIsOpen(false)
  }

  const handleFooterClick = () => {
    props.onFooterClick()
    setIsOpen(false)
    const input = getInput()
    if (input) {
      setTimeout(() => {
        input.focus()
      })
    }
  }

  const handleChange = value => {
    if (disabled) {
      return
    }

    if (!value) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
    const input = getInput()
    if (input) {
      input.focus()
    }
    props.onSearchChange(value)
  }

  const handleOnClosed = () => {
    handleChange('')
    setIsOpen(false)
  }

  const renderItem = (item, index) => (
    <DropdownItem
      item={item}
      index={index}
      dataTestId={dataTestId}
      scrollbarWrapperHeight={scrollbarWrapperHeight}
    />
  )

  const renderFooter = () => {
    if (!footerText) {
      return null
    }

    return (
      <div className={styles.dropdownFooter}>
        <ControlGroupItem
          dataTestId={`${dataTestId}-footer`}
          disabled={isFooterDisabled}
          icon={footerIcon}
          navigationItem={footerNavigationItem}
          onClick={handleFooterClick}
        >
          <div className={styles.dropdownFooterText}>{footerText}</div>
        </ControlGroupItem>
      </div>
    )
  }

  const renderSingleGroup = () => (
    <ControlGroupHolder>
      {items.map(renderItem)}
      {renderFooter()}
    </ControlGroupHolder>
  )

  const renderMultiGroup = () => {
    const reducedItems = items.reduce((accumulator, item) => {
      const { groupTitle, group, ...rest } = item

      const newAccumulator = accumulator

      if (!newAccumulator[groupTitle]) {
        newAccumulator[groupTitle] = {
          groupTitle,
          groups: [
            {
              label: group.label,
              items: [rest],
            },
          ],
        }
      } else {
        const existingGroup = newAccumulator[groupTitle].groups.find(g => g.label === group.label)

        if (existingGroup) {
          existingGroup.items.push(rest)
        } else {
          newAccumulator[groupTitle].groups.push({
            label: group.label,
            items: [rest],
          })
        }
      }

      return newAccumulator
    }, {})

    const categorizedItems = Object.values(reducedItems)

    return (
      <div>
        {categorizedItems.map(({ groupTitle, groups }, index) => (
          <div>
            {groupTitle && <div>{groupTitle}</div>}

            {groups.map(group => (
              <ControlGroupHolder
                title={group.label}
                key={group.label}
                divider={isGroupDivider && index !== categorizedItems.length - 1}
              >
                {group.items.map((item, itemIndex) => {
                  if (itemIndex < 4) return renderItem(item, itemIndex)
                  return null
                })}
                {renderFooter()}
              </ControlGroupHolder>
            ))}
          </div>
        ))}
      </div>
    )
  }

  const hasSomeGroup = items.some(item => item?.group?.label !== undefined)
  const selected = items.filter(item => item.checked)
  const value = selected.map(({ label: itemLabel }) => itemLabel).join(', ')
  const getValue = () => value

  return (
    <div
      className={styles.Dropdown}
      ref={node => {
        inputDropdownRef.current = node
      }}
    >
      <div className={styles.StyledInputContainer}>
        <Input
          dataTestId={dataTestId}
          dropdownRef={inputDropdownRef}
          isOpenDropdown={isOpen}
          disabled={disabled}
          label={label}
          onClick={handleClick}
          type='text'
          icon='search'
          placeholder={placeholder}
          value={getValue()}
          onChange={handleChange}
          onFocus={handleFocus}
          role='combobox'
          inlineEdit={inlineEdit}
        />
      </div>

      {isOpen && !disabled && (
        <div className={styles.StyledDropdownContainer} role='listbox' aria-expanded={isOpen}>
          <Dropdown
            scrollbarWrapperHeight={scrollbarWrapperHeight}
            scrollbarMaxHeight={scrollbarMaxHeight}
            dataTestId={dataTestId}
            role='listbox'
            autoFocusList={false}
            onClosed={handleOnClosed}
          >
            {categorized && hasSomeGroup ? renderMultiGroup() : renderSingleGroup()}
          </Dropdown>
        </div>
      )}
    </div>
  )
}

InputDropdownCustom.propTypes = {
  categorized: PropTypes.bool,
  isGroupDivider: PropTypes.bool,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      checked: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      thumbnail: PropTypes.string,
      groupTitle: PropTypes.string,
      group: PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    }),
  ),
  label: PropTypes.string,
  scrollbarWrapperHeight: PropTypes.number,
  scrollbarMaxHeight: PropTypes.bool,
  onClick: PropTypes.func,
  onSearchChange: PropTypes.func,
  placeholder: PropTypes.string,
  footerText: PropTypes.string,
  onFooterClick: PropTypes.func,
  isFooterDisabled: PropTypes.bool,
  footerIcon: PropTypes.string,
  footerNavigationItem: PropTypes.bool,
  inlineEdit: PropTypes.bool,
}

export default InputDropdownCustom
