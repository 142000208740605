import React from 'react'
import PropTypes from 'prop-types'

import { Text } from '@plurall/elo-beta'
import styles from './StatusInWords.module.css'

const StatusInWords = ({
  borderTop,
  icon,
  justifyContent,
  marginBottom,
  marginLeft,
  marginTop,
  paddingTop,
  status,
  iconMarginTop,
  size,
}) => (
  <React.Fragment>
    <div
      className={styles.status}
      style={{
        marginLeft,
        marginTop,
        marginBottom,
        paddingTop,
        borderTop,
        justifyContent,
      }}
      data-test-id='status-in-words'
    >
      <div className={styles.holder}>
        {icon && <div style={{ marginTop: iconMarginTop }}>{icon}</div>}
        <Text size={size || 't3'} semibold secondary>
          {status}
        </Text>
      </div>
    </div>
  </React.Fragment>
)

StatusInWords.propTypes = {
  borderTop: PropTypes.string,
  justifyContent: PropTypes.string,
  paddingTop: PropTypes.string,
  marginLeft: PropTypes.string,
  marginBottom: PropTypes.string,
  marginTop: PropTypes.string,
  status: PropTypes.string.isRequired,
  icon: PropTypes.node,
  iconMarginTop: PropTypes.string,
  size: PropTypes.string,
}

export default StatusInWords
