import React from 'react'
import PropTypes from 'prop-types'

import { Icon, Text } from '@plurall/elo-beta'

import styles from './Timer.module.css'

const NOTIFICATIONS_TIMER = 1

const inputZeroBefore = value => (value < 10 ? '0' : '')

const differenceDate = end => {
  const now = new Date().getTime()
  const countDownDate = new Date(end * 1000).getTime()

  const distance = countDownDate - now

  let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
  let seconds = Math.floor((distance % (1000 * 60)) / 1000)

  if (hours < 0) {
    hours = 0
  }

  if (minutes < 0) {
    minutes = 0
  }

  if (seconds < 0) {
    seconds = 0
  }

  return `${inputZeroBefore(hours)}${hours}:${inputZeroBefore(minutes)}${minutes}:${inputZeroBefore(
    seconds,
  )}${seconds}`
}

class Timer extends React.Component {
  state = { timer: differenceDate(this.props.endDate) }

  componentDidMount() {
    this.timer()
  }

  componentWillUnmount() {
    clearInterval(this.timer())
  }

  timer = () => setInterval(this.updateTimer, NOTIFICATIONS_TIMER * 1000)

  updateTimer = () => this.setState({ timer: differenceDate(this.props.endDate) })

  render() {
    return (
      <React.Fragment>
        <div className={styles.timer}>
          <Icon variant='brand' secondary type='Clock' />
          <Text secondary>{this.state.timer}</Text>
        </div>
      </React.Fragment>
    )
  }
}

Timer.propTypes = {
  endDate: PropTypes.number.isRequired,
}

export default Timer
