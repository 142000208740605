import queryString from 'query-string'
import PlurallClient from '@plurall/client'
import gql from 'graphql-tag'
import { loader } from 'graphql.macro'
import hacParams from 'utils/hacParams'
import { getToken } from 'utils/token'
import { getOauthClient } from 'utils/oauth'

const getPersonQuery = loader('../graphql/getPersonQuery.graphql')

class Client {
  constructor(config) {
    this.apiURL = process.env.REACT_APP_PLURALL_API_URL
    this.config = config
    this.token = getToken()
  }

  clientConfig = {
    accept: this.config ? this.config.accept : 'vnd.plurall.api.v3+json',
    pmVersion: 3,
    graphqlURL: process.env.REACT_APP_SOMOS_GRAPHQL_URL,
    disableAutoCamelize: false,
    applicationId: process.env.REACT_APP_PLURALL_ID_APPLICATION,
    apolloConfig: { fetchPolicy: 'no-cache' },
    onError: error => {
      if (error && error.response.status === 403) {
        const title = error.response.data.title // eslint-disable-line
        const message = error.response.data.msg || error.response.data.message
        window.location.href = `/error?title=${title}&message=${message}&status=403`
      }
    },
  }

  getPlurallClient = () => {
    const location = window.location.href
    const token = getToken()
    if (token !== this.token) {
      const OAuth = getOauthClient(location.pathname)
      window.location.href = OAuth.token.getUri()
      return null
    }

    const client = new PlurallClient({
      accessToken: token,
      ...this.clientConfig,
      apiURL: this.apiURL,
    })
    return client
  }

  getMenuClient = () => {
    const location = window.location.href
    const token = getToken()
    if (token !== this.token) {
      const OAuth = getOauthClient(location.pathname)
      window.location.href = OAuth.token.getUri()
      return null
    }

    const client = new PlurallClient({
      ...this.clientConfig,
      apiURL: `${process.env.REACT_APP_PLURALL_MENU_API_URL}/plurall`,
      accessToken: token,
    })
    return client
  }

  getProfile = () => this.getPlurallClient().profile.get(getPersonQuery)
  getMenus = () => this.getMenuClient().restClient.get()
  graphql = () => this.getPlurallClient().graphqlClient

  buildHacParams = () => {
    const { filter_context: filterContext } = hacParams() // eslint-disable-line
    // eslint-disable-next-line
    if (!filterContext || filterContext === 'undefined' || filterContext === undefined) {
      return {}
    }

    return {
      filter_context: filterContext,
    }
  }

  getMaterials = async context => {
    const { filter_context: filterContext } = hacParams()
    return this.getPlurallClient().restClient.post(`${this.apiURL}/filters/node_groups`, {
      filter_context: context || filterContext || {},
    })
  }
  getContextMaterials = async () => {
    const { filter_context: filterContext } = hacParams()
    return this.getPlurallClient().restClient.get(`${this.apiURL}/filters/context_groups`, {
      params: {
        filter_context: filterContext || 'all',
      },
    })
  }

  getTasks = ({ nodeGroup, page = 1 }) => {
    const { buildHacParams } = this
    const filters = queryString.parse(window.location.search)
    delete filters.filter_context
    const params = {
      ...buildHacParams(),
      ...filters,
      node_group: nodeGroup,
      page,
    }

    return this.getPlurallClient().restClient.get(`${this.apiURL}/task_workflows`, { params })
  }

  getTask = async ({ taskId }) => {
    const { buildHacParams } = this

    return this.getPlurallClient().restClient.get(`${this.apiURL}/task_workflows/${taskId}`, {
      params: buildHacParams(),
    })
  }

  getFilters = async ({ materialId }) => {
    const { buildHacParams } = this

    return this.getPlurallClient().restClient.get(
      `${this.apiURL}/filters?node_group=${materialId}`,
      {
        params: buildHacParams(),
      },
    )
  }

  getQuickTasks = async ({ lessonId, totalTask }) =>
    this.getPlurallClient().restClient.get(
      `${this.apiURL}/quick_answers/${lessonId}${
        Number.parseInt(totalTask, 10) ? `?limit=${totalTask}` : '?limit=999'
      }`,
    )

  submitTask = async ({ userTaskId }) =>
    this.getPlurallClient().restClient.post(`${this.apiURL}/user_tasks/${userTaskId}/finish`, {
      params: {},
    })

  submitQuickTasks = async ({ taskWorkflow, lessonId }) =>
    this.getPlurallClient().restClient.post(`${this.apiURL}/quick_answers/${lessonId}/answer`, {
      taskWorkflow,
    })

  getExercise = async url => this.getPlurallClient().restClient.get(url)

  getQuestion = async url => this.getPlurallClient().restClient.get(url)

  submitAnswer = async ({ exerciseId, file, message, sequence, taskId }) => {
    if (file) {
      return this.getPlurallClient().restClient.post(
        `${this.apiURL}/task_workflows/${taskId}/subtasks/${exerciseId}/answer`,
        {
          image: file,
        },
      )
    }

    return this.getPlurallClient().restClient.post(
      `${this.apiURL}/task_workflows/${taskId}/subtasks/${exerciseId}/answer`,
      {
        answer: message || sequence,
      },
    )
  }

  unlockQuestion = async ({ exerciseId, taskId }) =>
    this.getPlurallClient().restClient.put(
      `${this.apiURL}/task_workflows/${taskId}/subtasks/${exerciseId}/release_lock`,
      {
        params: {},
      },
    )

  finishButton = async ({ url, key }) =>
    this.getPlurallClient().restClient.post(url, {
      key,
    })

  getBannerAvaliacoes = async () => {
    const { data } = await this.graphql().query({
      query: gql`
        query accountAssessmentsBanner {
          person {
            id
            roles(status: [ACTIVE]) {
              id
              name
              classes(
                status: "ATIVO"
                periodId: 7
                gradeIds: [
                  100673
                  100634
                  100638
                  100662
                  100663
                  100664
                  100665
                  100666
                  100668
                  100667
                  100669
                  100670
                ]
              ) {
                levelGrades {
                  levelName
                  gradeName
                }
              }
            }
          }
        }
      `,
      variables: {},
      fetchPolicy: 'network-only',
    })
    return data
  }
}

export default Client
