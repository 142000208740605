import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'

import { Card } from '@plurall/elo-beta'

import { getUserName } from 'utils/getUserName'
import { TitleContext } from 'utils/context'
import hacParams from 'utils/hacParams'

import SubHeader from 'App/components/SubHeader'
import Wrapper from 'App/components/Wrapper'

import styles from './TaskMaterial.module.scss'

const TaskMaterial = ({ fetchTask, onListUpdated, status, subTitle, task, url }) => (
  <Route
    render={({ match: { params } }) => {
      const { queryParameters } = hacParams()
      const goBack = `/material/${params.materialId}/aula/${params.lessonId}/tarefa/${params.taskId}/${queryParameters}`

      if (!url) {
        return <Redirect to={goBack} />
      }

      return (
        <React.Fragment>
          <TitleContext.Consumer>
            {data => (
              <SubHeader
                subTitle={`${data.taskListTitle} / ${subTitle}`}
                title={task.name}
                user={getUserName(data.userProfile)}
                backTo={goBack}
              />
            )}
          </TitleContext.Consumer>

          <Wrapper padding='20px 0 0 0'>
            <Card>
              <div className={styles['task-material-container']}>
                <iframe
                  onLoad={() => {
                    if (!status) {
                      fetchTask()
                      onListUpdated()
                    }
                  }}
                  src={url}
                  title='task-material'
                />
              </div>
            </Card>
          </Wrapper>
        </React.Fragment>
      )
    }}
  />
)

TaskMaterial.propTypes = {
  fetchTask: PropTypes.func.isRequired,
  onListUpdated: PropTypes.func.isRequired,
  status: PropTypes.string,
  subTitle: PropTypes.string.isRequired,
  task: PropTypes.object,
  url: PropTypes.string,
}

export default TaskMaterial
