import React from 'react'
import PropTypes from 'prop-types'

import { Card, Heading } from '@plurall/elo-beta'

import GridLayout from 'App/components/GridLayout'

import { trackingEventNames } from 'App/constants/tracking'

import MediaTask from './MediaTask'
import TaskStatus from './TaskStatus'

import styles from './MediaTasks.module.scss'

const MediaTasks = ({ mediaTasks, officialAnswerInWords, onTaskClick, statusInWords, video }) => (
  <Card secondary>
    <div className={styles['media-tasks-wrapper']}>
      <GridLayout
        media={video}
        onMediaClick={() =>
          window.PLURALL_TRACKER.track(trackingEventNames.mediaTasks.subtaskGroupVideoExternalClick)
        }
      >
        <div className={styles['task-status']}>
          {statusInWords && (
            <TaskStatus
              officialAnswerInWords={officialAnswerInWords}
              statusInWords={statusInWords}
            />
          )}

          {mediaTasks?.length > 0 && (
            <Heading element='h3' noParagraph secondary>
              {mediaTasks[0].title}
            </Heading>
          )}
        </div>

        {mediaTasks?.length > 0 &&
          mediaTasks.map(({ subtasks, title: lessonTitle }) => (
            <MediaTask
              key={lessonTitle}
              onTaskClick={onTaskClick}
              subtasks={subtasks}
              statusInWords={statusInWords}
              video={video}
            />
          ))}
      </GridLayout>
    </div>
  </Card>
)

MediaTasks.propTypes = {
  mediaTasks: PropTypes.array.isRequired,
  officialAnswerInWords: PropTypes.string,
  onTaskClick: PropTypes.func.isRequired,
  statusInWords: PropTypes.string,
  video: PropTypes.object,
}

export default MediaTasks
