import React from 'react'
import PropTypes from 'prop-types'

import { InputDropdown, Text } from '@plurall/elo-beta'

import styles from './Filter.module.css'

const Filter = ({ filters, onChange, text = 'Disciplina', dataTestId }) => (
  <div className={styles['disciplines-filter']} data-test-id={dataTestId}>
    <div className={styles['filter-group-name']}>
      <Text size='t3' secondary textTransform='uppercase'>{`${text}:`}</Text>
    </div>

    <div className={styles.dropdown}>
      <InputDropdown scrollbarMaxHeight={400} items={filters} onChange={onChange} />
    </div>
  </div>
)

const valuesProps = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string,
  }),
)

Filter.propTypes = {
  filters: valuesProps.isRequired,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string,
  dataTestId: PropTypes.string,
}

export default Filter
