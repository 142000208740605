import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'react-app-polyfill/ie9'
import React, { useRef, useEffect } from 'react'
import { BrowserRouter, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import LoginCallback from 'LoginCallback'
import Layout from 'App/containers/Layout'
import MaterialList from 'App/views/MaterialList'
import Error from 'App/views/Error'
import Client from 'utils/client'
import { UserProvider } from 'contexts/UserContext'
import PrivateRoute from 'App/components/PrivateRoute/PrivateRoute'
import { createRoot } from 'react-dom/client'
import { initSentry } from 'utils/sentry'
import 'index.css'

let environment = process.env.REACT_APP_NODE_ENV
if (environment === 'pull_request' || environment === 'local') {
  environment = 'staging'
}

window.PLURALL_TRACKER = new window.PlurallTracker({
  clientId: 'activities',
  environment,
  plurallFeedback: 1,
})

const callbackHistory = []
window.PLURALL_CUSTOM_HISTORY = {
  listen: callback => {
    if (!callbackHistory.find(c => c === callback)) {
      callbackHistory.push(callback)
    }
  },
}

const History = ({ children }) => {
  const sentryInitilized = useRef(false)
  const location = useLocation()
  const history = useHistory()
  useEffect(() => {
    callbackHistory.forEach(callback => {
      callback(location)
    })
  }, [location])
  useEffect(() => {
    if (history && !sentryInitilized.current) {
      initSentry(history)
      sentryInitilized.current = true
    }
  }, [history, sentryInitilized])
  return children
}

class Main extends React.Component {
  async componentDidMount() {
    window.PLURALL_TRACKER.pageView()
  }

  client = new Client({ accept: 'vnd.plurall.api.v2+json' })

  render() {
    return (
      <Layout>
        <Route path='/error' render={props => <Error {...props} />} />
        <Route path='(/|/material)' component={MaterialList} />
      </Layout>
    )
  }
}

const root = createRoot(document.getElementById('root'))
if (!root) throw new Error('Failed to find the root element')

root.render(
  <BrowserRouter>
    <History>
      <Switch>
        <Route path='/login/callback' component={LoginCallback} />
        <UserProvider>
          <PrivateRoute component={Main} />
        </UserProvider>
      </Switch>
    </History>
  </BrowserRouter>,
)
