import PropTypes from 'prop-types'
import React from 'react'

const Wrapper = ({ children, padding = '0' }) => (
  <div
    style={{
      padding,
      margin: 'auto',
      display: 'flex',
      flexFlow: 'column',
    }}
    data-test-id='wrapper'
  >
    {children}
  </div>
)

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.string,
}

export default Wrapper
