import PropTypes from 'prop-types'
import React, { Component } from 'react'
import queryString from 'query-string'

import { Grid, Row } from '@plurall/elo-beta'
import { Layout as LayoutElo } from '@plurall/layout'

import { getToken } from 'utils/token'
import Client from 'utils/client'

import ErrorBoundary from 'App/components/ErrorBoundary/ErrorBoundary'
import { TitleContext } from 'utils/context'
import hacParams from 'utils/hacParams'
import BannerSurvey from 'App/components/BannerSurvey'

const mapFilterContextToSlugProject = {
  0: 'tasks',
  13: 'tasks:hac',
  17: 'tasks:olympic',
  18: 'tasks:simulados',
  19: 'tasks:provas',
  23: 'tasks:standard-tests',
  24: 'tasks:test_in_time',
  28: 'tasks:maxi-test',
  40: 'tasks:eleva_simple_task',
  41: 'tasks:eleva_eletivas_task',
}

class Layout extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  state = {
    loading: true,
    service: mapFilterContextToSlugProject[0],
    user: {},
    serviceName: '',
    bannerSurveyData: null,
  }

  componentDidMount() {
    const client = new Client()

    client.getBannerAvaliacoes().then(({ person }) => {
      this.setState({ bannerSurveyData: person })
    })
    client.getProfile().then(user => {
      this.setState({ user })
    })

    client.getMenus().then(({ data: { menu } }) => {
      const { filter_context: filterContext } = hacParams()
      const { 'simulados-e-provas': simuladosEprovas } = queryString.parse(window.location.search)
      let keySlug = 0

      if (simuladosEprovas) {
        keySlug = 18
      } else {
        keySlug = filterContext === undefined || filterContext === 'undefined' ? 0 : filterContext
      }

      const HasAssignmentItem = menu.items.find(({ slug }) => slug === 'assignment')
      const hasSimuladosEProvas = menu.items.find(({ slug }) => slug === 'simulados-e-provas')
      const hasPlurallOlimpic = menu.items.find(({ slug }) => slug === 'tasks:olympic')
      const hasStandardTests = menu.items.find(({ slug }) => slug === 'tasks:standard-tests')
      const hasMaxiTest = menu.items.find(({ slug }) => slug === 'tasks:maxi-test')
      const hasElevaSimpleTest = menu.items.find(({ slug }) => slug === 'tasks:eleva_simple_task')
      const hasElevaEletivasTest = menu.items.find(
        ({ slug }) => slug === 'tasks:eleva_eletivas_task',
      )
      const items = []

      if (HasAssignmentItem) {
        items.push(HasAssignmentItem.subItems)
      }
      if (hasSimuladosEProvas) {
        items.push(hasSimuladosEProvas.subItems)
      }
      if (hasPlurallOlimpic) {
        items.push(hasPlurallOlimpic)
      }
      if (hasStandardTests) {
        items.push(hasStandardTests)
      }
      if (hasMaxiTest) {
        items.push(hasMaxiTest)
      }
      if (hasElevaSimpleTest) {
        items.push(hasElevaSimpleTest)
      }
      if (hasElevaEletivasTest) {
        items.push(hasElevaEletivasTest)
      }

      const item = items
        .filter(e => e !== undefined)
        .flat()
        .find(({ slug }) => slug === mapFilterContextToSlugProject[keySlug])

      if (item) {
        window.document.title = `${item.name} - ${window.document.title}`

        let service = item.slug
        if (
          item.slug === mapFilterContextToSlugProject[18] ||
          item.slug === mapFilterContextToSlugProject[19]
        ) {
          service = hasSimuladosEProvas.slug
        }

        if (item.slug === mapFilterContextToSlugProject[17]) {
          service = hasPlurallOlimpic.slug
        }

        this.setState({
          taskListTitle: item.name,
          serviceName: item.name.toLowerCase(),
          service,
          loading: false,
        })
      } else {
        this.setState({ taskListTitle: 'Atividades', serviceName: 'atividades', loading: false })
      }
    })
  }

  render() {
    const {
      props: { children },
      state: { bannerSurveyData, loading, taskListTitle, user, service, serviceName },
    } = this

    if (loading) {
      return null
    }

    return (
      <LayoutElo
        menuURL={
          process.env.REACT_APP_PLURALL_MENU_API_URL
            ? `${process.env.REACT_APP_PLURALL_MENU_API_URL}/plurall`
            : ''
        }
        apiURL={process.env.REACT_APP_PLURALL_API_URL}
        applicationId={process.env.REACT_APP_PLURALL_ID_APPLICATION}
        graphqlURL={process.env.REACT_APP_SOMOS_GRAPHQL_URL}
        userToken={getToken()}
        service={service}
        menuSubItem={service}
        projectName={serviceName}
        msApiURL={process.env.REACT_APP_MS_API_URL}
        apiKey={process.env.REACT_APP_FIREBASE_API_KEY}
        authDomain={process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}
        projectId={process.env.REACT_APP_FIREBASE_PROJECT_ID}
        storageBucket={process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}
        messagingSenderId={process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}
        appId={process.env.REACT_APP_FIREBASE_APP_ID}
        measurementId={process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}
        hideGlobalMenu
      >
        <Row>
          <Grid desktop={12} tablet={12}>
            <TitleContext.Provider
              value={{
                userProfile: user,
                taskListTitle,
              }}
            >
              <ErrorBoundary>
                <BannerSurvey person={bannerSurveyData} />
                {children}
              </ErrorBoundary>
            </TitleContext.Provider>
          </Grid>
        </Row>
      </LayoutElo>
    )
  }
}

export default Layout
