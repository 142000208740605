let features

let environment = process.env.REACT_APP_NODE_ENV

if (environment === 'pull_request' || environment === 'local') {
  environment = 'staging'
}

const { REACT_APP_FEATURE_TOGGLES_URL } = process.env

const fetchFeatures = async env => {
  const currentDate = new Date()
  const getHours = currentDate.getHours()
  const getMinutes = currentDate.getMinutes()
  const getTime = `${getHours}_${getMinutes}`

  await fetch(`${REACT_APP_FEATURE_TOGGLES_URL}/${env}.json?v=${getTime}`)
    .then(response => response.json())

    .then(response => {
      features = { ...response }
    })

    .catch()
}

const getFeature = async feature => {
  await fetchFeatures(environment)

  try {
    return !!features && features[feature]
  } catch {
    return false
  }
}

const FEATURE_BANNER_SURVEY_MONKEY = 'banner_survey_monkey'

export { fetchFeatures, getFeature, FEATURE_BANNER_SURVEY_MONKEY }
