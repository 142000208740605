import React from 'react'
import PropTypes from 'prop-types'

import { Card, Divider, Text } from '@plurall/elo-beta'

import Timer from 'App/components/Timer'
import Paginator from 'App/components/Paginator'

import styles from './Exercise.module.scss'

const Exercise = ({
  children,
  current,
  hasHelpOptions,
  onPaginate,
  progressInWords,
  showExerciseHeader,
  total,
  task,
}) => (
  <section className={styles['main-content']} style={{ width: hasHelpOptions ? '70%' : '100%' }}>
    <Card>
      <div className={styles['card-wrapper']}>
        {showExerciseHeader && (
          <React.Fragment>
            {task.endDate && (
              <React.Fragment>
                <div className={styles.timer}>
                  <Timer endDate={task.endDate} />
                </div>
                <div className={styles.divider}>
                  <Divider light />
                </div>
              </React.Fragment>
            )}
            <div className={styles.container}>
              <Text semibold secondary>
                {progressInWords}
              </Text>

              <Paginator current={current} paginateCallback={onPaginate} total={total} />
            </div>

            <div className={styles.divider}>
              <Divider light />
            </div>
          </React.Fragment>
        )}

        {children}
      </div>
    </Card>
  </section>
)

Exercise.propTypes = {
  children: PropTypes.node.isRequired,
  current: PropTypes.number.isRequired,
  hasHelpOptions: PropTypes.bool.isRequired,
  onPaginate: PropTypes.func.isRequired,
  progressInWords: PropTypes.string.isRequired,
  showExerciseHeader: PropTypes.bool,
  total: PropTypes.number.isRequired,
  task: PropTypes.object,
}

export default Exercise
