import {
  DOCUMENT,
  DOUBTS,
  FILE,
  TEST_FILE,
  VIDEO_EXTERNAL_ALT,
  VIDEO_EXTERNAL,
  VIDEO_RESOLUTION,
  VIDEO,
} from 'App/constants/supportMaterialTypes'

const getSupportMaterialType = ({ key, type }) => {
  const doubts = key === DOUBTS
  const isExternalVideo = key === VIDEO_EXTERNAL || key === VIDEO_EXTERNAL_ALT
  const isResolutionVideo = key === VIDEO_RESOLUTION
  const isTextDocument = (key === FILE && type === DOCUMENT) || key === TEST_FILE
  const isVideo = type === VIDEO

  return {
    doubts,
    isExternalVideo,
    isResolutionVideo,
    isTextDocument,
    isVideo,
  }
}

export default getSupportMaterialType
