const trackingEventNames = {
  taskGroup: {
    quickAnswerClick: 'quick-answer:click',
    askListVideoClick: 'task-list:video:external:click',
  },
  taskList: {
    onlyAvailableTodo: 'task-list:only-available-todo',
  },
  supportCard: {
    questionReadClick: 'question:read:click',
    questionDoubtsClick: 'question:doubts:click',
    videoCantoLousaExternalClick: 'question:video:canto_lousa:external:click',
  },
  taskExercise: {
    questionPopup: 'question:popup',
    questionAnswerSubmit: 'question-answer:submit',
  },
  mediaTasks: {
    subtaskGroupVideoExternalClick: 'subtask-group:video:external:click',
  },
  quickAnswer: {
    quickAnswerSubmit: 'quick-answer:submit',
  },
  finishButton: {
    eoEndTestClick: 'eo-tests:end-test-button:click',
    eoEndTestConfirmationClickSuccess: 'eo-tests:end-test-confirmation-button:click:success',
    eoEndTestConfirmationClickFailure: 'eo-tests:end-test-confirmation-button:click:failure',
  },
}

export { trackingEventNames }
