import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as PlayIcon } from 'App/icons/Play.svg'
import { BookCover, Card, Dialog } from '@plurall/elo-beta'

import styles from './MediaContent.module.css'

const MediaContent = ({ onMediaClick, thumbnail, title = 'Canto de Lousa', url }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [showImage, setShowImage] = useState(true)

  function onClose() {
    setIsVisible(!isVisible)
  }

  return (
    <div
      className={styles['media-wrapper']}
      onClick={() => {
        if (!isVisible) {
          onMediaClick()
        }

        setIsVisible(!isVisible)
      }}
      data-test-id='media-content'
    >
      {isVisible && (
        <Dialog size='large' onClose={onClose} title={title}>
          <div className={styles['video-wrapper']} data-test-id='media-content-inside-modal'>
            <iframe src={url} title='Support video' allowFullScreen />
          </div>
        </Dialog>
      )}

      <Card>
        <div className={styles['card-wrapper']}>
          {thumbnail && showImage ? (
            <img
              className={styles.thumbnail}
              src={thumbnail}
              alt='Video preview'
              onError={() => setShowImage(false)}
            />
          ) : (
            <div className={styles['default-thumbnail']}>
              <BookCover src='' margin='-10px' height='200px' width='144px' />
            </div>
          )}
          <div className={styles.play}>
            <PlayIcon />
          </div>
          <span className={styles.title}>{title}</span>
        </div>
      </Card>
    </div>
  )
}

MediaContent.propTypes = {
  onMediaClick: PropTypes.func.isRequired,
  thumbnail: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
}

export default MediaContent
