import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import { trackOnWrongAnswer } from 'utils/tracker'

import SupportCard from './SupportCard'

const WrongAnswerButtons = ({
  helpOptions: { doubtsLink, mediaLinks },
  onClick,
  dismissButton,
  taskName,
  userMessage,
}) => (
  <Route
    render={({ match }) => {
      const trackingParams = {
        task_name: taskName,
        material: match.params.materialId,
      }

      return (
        <React.Fragment>
          {doubtsLink && (
            <SupportCard
              key={doubtsLink.title}
              onClick={() => {
                trackOnWrongAnswer({
                  dismissButton,
                  option: doubtsLink,
                  trackingParams,
                })

                onClick(doubtsLink.url)
              }}
              option={doubtsLink}
              taskName={taskName}
              userMessage={userMessage}
            />
          )}

          {mediaLinks.map(option => (
            <SupportCard
              key={option.title}
              onClick={() => {
                trackOnWrongAnswer({
                  dismissButton,
                  option,
                  trackingParams,
                })

                onClick(option.url)
              }}
              option={option}
              taskName={taskName}
              userMessage={userMessage}
            />
          ))}
        </React.Fragment>
      )
    }}
  />
)

WrongAnswerButtons.propTypes = {
  helpOptions: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  dismissButton: PropTypes.object.isRequired,
  taskName: PropTypes.string.isRequired,
  userMessage: PropTypes.object.isRequired,
}

export default WrongAnswerButtons
