import PropTypes from 'prop-types'
import React from 'react'

import { Icon } from '@plurall/elo-beta'

import styles from './Progress.module.scss'

const calcCompleteTasks = ({
  answered = 0,
  correct = 0,
  total = 0,
  nullified = 0,
  wrong = 0,
  progress = 0,
}) => {
  const sum = answered + correct + nullified + wrong + progress
  return sum === total
}

const Progress = ({ answered, correct, locked, nullified, total, wrong, progress }) => {
  const calcWidth = element => `${(element / total) * 100}%`

  const noAnswers = answered === 0
  const isLocked = locked === 0

  return (
    <div className={styles['progress-holder']}>
      {answered >= 0 && (
        <div
          className={styles['progress-item']}
          style={{
            width: noAnswers ? '5%' : calcWidth(answered),
            background: 'var(--color-purple40)',
            justifyContent: 'flex-end',
          }}
        >
          <div className='answered'>{answered}</div>
        </div>
      )}
      {progress >= 0 && (
        <div
          className={styles['progress-item']}
          style={{
            width: calcWidth(progress),
            background: 'var(--color-purple40)',
            justifyContent: 'flex-end',
          }}
        >
          <div className='answered'>{progress}</div>
        </div>
      )}
      {isLocked && (
        <div className={`${styles['progress-item']} ${styles['finished-unanswered']}`}>
          <Icon type='Lock' />
          <div>{locked}</div>
        </div>
      )}
      {correct && (
        <div
          className={styles['progress-item']}
          style={{ background: 'var(--color-mint30)', width: calcWidth(correct) }}
        >
          <Icon type='Check' />
          <div className='correct'>{correct}</div>
        </div>
      )}
      {wrong && (
        <div
          className={styles['progress-item']}
          style={{ width: calcWidth(wrong), background: 'var(--color-red30)' }}
        >
          <Icon type='Cancel' />
          <div className='wrong'>{wrong}</div>
        </div>
      )}
      {nullified && (
        <div
          className={styles['progress-item']}
          style={{ width: calcWidth(nullified), background: 'var(--color-shade60)' }}
        >
          <Icon type='Not-allowed' />
          <div className='nullified'>{nullified}</div>
        </div>
      )}
      {!calcCompleteTasks({ answered, correct, total, nullified, wrong, progress }) && (
        <div
          className={`${styles['progress-item']} ${styles.total}`}
          style={{
            color: 'var(--color-shade70)',
            position: 'absolute',
            right: 20,
            top: 0,
            height: '100%',
            width: 20,
          }}
        >
          {total}
        </div>
      )}
    </div>
  )
}

Progress.propTypes = {
  answered: PropTypes.number,
  correct: PropTypes.number,
  locked: PropTypes.number,
  nullified: PropTypes.number,
  total: PropTypes.number,
  wrong: PropTypes.number,
  progress: PropTypes.number,
}

export default Progress
