import React from 'react'
import PropTypes from 'prop-types'
import { Link, Route } from 'react-router-dom'

import { Card, Icon, Text, Tooltip } from '@plurall/elo-beta'

import { BLANK, NULLIFIED, UNAVAILABLE } from 'App/constants/exerciseStatus'

import hacParams from 'utils/hacParams'

import styles from './ExerciseCard.module.scss'

const MAX_CHARS_ANSWERED = 20
const MAX_CHARS_UNANSWERED = 30

const renderIcon = (icon, padding) => (
  <div className={`${styles['icon-holder']} ${styles[padding]}`}>{icon}</div>
)

const exerciseStatusMap = {
  answered: {
    icon: null,
  },
  blank: {
    icon: renderIcon(<Icon type='Lock' shy />, 'medium-padding'),
    text: 'Não respondida',
  },
  correct: {
    icon: renderIcon(<Icon type='Check' variant='positive' size='big' shy />, 'small-padding'),
    whiteText: true,
  },
  nullified: {
    shy: true,
    icon: renderIcon(<Icon type='Not-allowed' shy />),
    text: 'Anulada',
  },
  unavailable: {
    shy: true,
    icon: null,
    text: 'Indisponível',
  },
  wrong: {
    icon: renderIcon(<Icon type='Cancel' variant='negative' shy />, 'medium-padding'),
    whiteText: true,
  },
  progress: {
    icon: null,
  },
}

const defaultMapping = {
  ...exerciseStatusMap,
  blank: {
    icon: null,
    text: '',
  },
}

const activityStatusMap = {
  available: defaultMapping,
  finished: exerciseStatusMap,
  unavailable: defaultMapping,
}

const ExerciseCard = ({ activityStatus, exerciseStatus, id, sequence, selectedOption }) => {
  const notAvailable = exerciseStatus === NULLIFIED || exerciseStatus === UNAVAILABLE
  const unanswered = exerciseStatus === BLANK

  const answerStatus = activityStatusMap[activityStatus][exerciseStatus]

  const MAX_CHARS = notAvailable || unanswered ? MAX_CHARS_UNANSWERED : MAX_CHARS_ANSWERED

  const { queryParameters } = hacParams()

  return (
    <Route
      render={({ location: { pathname } }) => (
        <Link
          to={`${pathname}${pathname.endsWith('/') ? '' : '/'}exercicio/${id}/${queryParameters}`}
        >
          <div className={`${styles['exercise-card']} ${styles[exerciseStatus]}`}>
            <Card interactive>
              <div className={`${styles['card-wrapper']} ${styles[exerciseStatus]}`}>
                {answerStatus.icon}

                <div className={styles['text-wrapper']}>
                  {sequence.length > MAX_CHARS ? (
                    <Tooltip title={sequence}>
                      <div className={answerStatus.whiteText ? styles.white : ''}>
                        <Text secondary shy={answerStatus.shy} semibold>
                          {sequence.slice(0, MAX_CHARS)} ...
                        </Text>
                      </div>
                    </Tooltip>
                  ) : (
                    <div className={answerStatus.whiteText ? styles.white : ''}>
                      <Text secondary semibold shy={answerStatus.shy}>
                        {sequence}
                      </Text>
                    </div>
                  )}

                  {(answerStatus.text || selectedOption) && (
                    <React.Fragment>
                      <div className={answerStatus.whiteText ? styles.white : ''}>
                        <Text
                          size={notAvailable || unanswered ? 't5' : 't2'}
                          semibold
                          alignment='center'
                        >
                          {notAvailable || unanswered ? answerStatus.text : selectedOption}
                        </Text>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </Card>
          </div>
        </Link>
      )}
    />
  )
}

ExerciseCard.propTypes = {
  activityStatus: PropTypes.oneOf(['available', 'finished', 'unavailable']).isRequired,
  exerciseStatus: PropTypes.oneOf([
    'answered',
    'blank',
    'correct',
    'nullified',
    'unanswered',
    'unavailable',
    'wrong',
  ]),
  id: PropTypes.number.isRequired,
  selectedOption: PropTypes.string,
  sequence: PropTypes.string.isRequired,
}

export default ExerciseCard
