import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import queryString from 'query-string'

import InfiniteScroll from 'react-infinite-scroller'

import { Checkbox, Spinner } from '@plurall/elo-beta'

import Client from 'utils/client'
import { getUserName } from 'utils/getUserName'
import hacParams from 'utils/hacParams'
import { TitleContext } from 'utils/context'
import { trackingEventNames } from 'App/constants/tracking'
import filterPresenter from 'App/presenters/FiltersPresenter'

import ErrorMessage from 'App/components/ErrorMessage/ErrorMessage'
import SubHeader from 'App/components/SubHeader'
import Wrapper from 'App/components/Wrapper'
import Filter from 'App/components/Filter'
import Task from 'App/views/Task'
import QuickAnswer from 'App/views/QuickAnswer'
import Loading from 'App/components/Loading'
import TaskGroupCard from './components/TaskGroup'

import styles from './index.module.scss'

class TaskListContainer extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.currentPage = 1
    this.discipline = null
    this.limit = 10
    this.onlyAvailableTodo = false
  }

  state = { taskList: [], loading: true, checked: false }

  async componentDidMount() {
    const {
      location: { pathname },
      match: {
        params: { materialId },
      },
    } = this.props

    this.setTaskListName()

    const {
      data: { data: filters, result: resultFilters },
    } = await this.client.getFilters({
      path: pathname,
      materialId,
    })

    if (resultFilters === 'success' && filters.length > 0) {
      const [disciplinesFilter] = filters

      this.setState({
        normalizedFilters: filterPresenter(disciplinesFilter),
      })
    }

    this.setTaskList()
  }

  async componentWillUnmount() {
    this.resetSearchUrl()
  }

  onFilterClick = filter => {
    this.disciplinesFilter = filter || undefined
    this.resetTaskList()
  }

  setTaskList = async () => {
    const {
      data: { data: taskList, total },
    } = await this.fetchTaskList()

    this.setState({
      loading: false,
      taskList,
      totalPages: this.getTotalPages(total),
      totalTask: total,
    })
  }

  setTaskListName = async () => {
    const {
      data: {
        data: { values: material },
      },
    } = await this.client.getMaterials()

    const item = material.find(({ id }) => id === this.props.match.params.materialId)
    if (item && item.value) {
      this.setState({
        name: item.value,
      })
    }
  }

  getTotalPages = total => Math.ceil(total / this.limit)

  client = new Client()

  fetchTaskList = () => {
    const { buildParams, client } = this
    return client.getTasks(buildParams())
  }

  buildParams = () => {
    const {
      currentPage,
      props: {
        match: {
          params: { materialId },
        },
      },
    } = this

    return {
      nodeGroup: materialId,
      page: currentPage,
    }
  }

  loadMore = async () => {
    this.currentPage += 1
    const {
      data: { data: taskList, result },
    } = await this.fetchTaskList()

    if (result === 'success') {
      this.setState({
        taskList: [...this.state.taskList, ...taskList],
      })
    }
  }

  resetTaskList = () => {
    this.currentPage = 1
    this.setState({ loading: true, taskList: [] }, async () => {
      const {
        data: { data: taskList, result, total },
      } = await this.fetchTaskList()

      if (result === 'success') {
        this.setState({
          loading: false,
          taskList,
          totalPages: this.getTotalPages(total),
        })
      }
    })
  }

  resetSearchUrl = () => {
    const { filter_context: filterContext } = hacParams()
    const search = {}
    if (filterContext) {
      search.filter_context = filterContext
    }
    this.props.history.replace({ search: queryString.stringify(search) })
  }

  updateSearchUrl = () => {
    const { filter_context: filterContext } = hacParams()
    const search = {}

    if (filterContext) {
      search.filter_context = filterContext
    }

    if (this.state.checked) {
      search.only_available_todo = this.state.checked
    }

    if (this.state.selected) {
      search['search_disciplines[]'] = this.state.selected
    }

    this.props.history.replace({ search: queryString.stringify(search) })
  }

  handleCheckboxClick = () => {
    this.setState(
      ({ checked }) => ({ checked: !checked }),
      () => {
        this.onlyAvailableTodo = this.state.checked
        window.PLURALL_TRACKER.track(trackingEventNames.taskList.onlyAvailableTodo, {
          check: this.state.checked,
        })
        this.resetTaskList()
        this.updateSearchUrl()
      },
    )
  }

  handleFilterClick = selected => {
    const normalizedFilters = this.state.normalizedFilters.map(item => {
      if (item.id === selected.id) {
        return { ...item, checked: true }
      }

      return { ...item, checked: false }
    })

    this.setState({ selected: selected.id, normalizedFilters }, () => {
      this.disciplinesFilter = this.state.selected || undefined
      this.resetTaskList()
      this.updateSearchUrl()
    })
  }

  loader = () => <Spinner key={0} size='large' />

  renderTaskList = () => {
    const {
      currentPage,
      loader,
      loadMore,
      setTaskList,
      state: { taskList, totalPages, totalTask },
    } = this

    if (taskList.length === 0) {
      return <ErrorMessage subTitle='Verifique o filtro realizado.' />
    }

    return (
      <InfiniteScroll
        pageStart={1}
        loadMore={loadMore}
        loader={loader()}
        hasMore={currentPage < totalPages}
      >
        {taskList.map((task, index) => (
          <TaskGroupCard
            discipline={task.subtitle}
            index={index}
            key={task.nodeId}
            lesson={task.title}
            lessonId={task.nodeId}
            quickAnswer={task.quickAnswer}
            tasks={task.tasks}
            video={task.video}
            endDate={task.endDate}
            finishButton={task.finishButton}
            receiptButton={task.receiptButton}
            totalTasks={totalTask}
            onListUpdated={setTaskList}
          />
        ))}
      </InfiniteScroll>
    )
  }

  render() {
    const {
      renderTaskList,
      setTaskList,
      handleCheckboxClick,
      handleFilterClick,
      props: {
        match: { path },
      },
      state: { checked, normalizedFilters, loading, name },
    } = this

    const { queryParameters } = hacParams()

    return (
      <React.Fragment>
        <Route
          path={`${path}/aula/:lessonId/tarefa/:taskId`}
          render={props => <Task {...props} name={name} onListUpdated={setTaskList} />}
        />
        <Route
          path={`${path}/aula/:lessonId/resposta-rapida`}
          render={props => <QuickAnswer {...props} onListUpdated={setTaskList} />}
        />

        <Route
          exact
          path={path}
          render={() => (
            <div id='task-list'>
              <TitleContext.Consumer>
                {data => (
                  <SubHeader
                    subTitle={data.taskListTitle}
                    title={name}
                    user={getUserName(data.userProfile)}
                    backTo={`/${queryParameters}`}
                  />
                )}
              </TitleContext.Consumer>

              <Wrapper padding='0 0 20px 0'>
                <div className={styles['filter-container']} data-test-id='disciplines-filter'>
                  {normalizedFilters && (
                    <Filter filters={normalizedFilters} onChange={handleFilterClick} />
                  )}

                  <div className={styles.checkbox} data-test-id='check-disciplines-filter'>
                    <Checkbox
                      id='check-disciplines-filter'
                      name='checkbox-filter'
                      label='Visualizar apenas tarefas para fazer'
                      checked={checked}
                      onChange={handleCheckboxClick}
                    />
                  </div>
                </div>
                {loading ? <Loading padding='120px 0 0 0' /> : renderTaskList()}
              </Wrapper>
            </div>
          )}
        />
      </React.Fragment>
    )
  }
}

export default TaskListContainer
