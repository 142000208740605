const ANSWERED = 'answered'
const BLANK = 'blank'
const CORRECT = 'correct'
const ESSAY = 'essay'
const FINISHED = 'finished'
const SELECTED = 'selected'
const MULTIPLE_CHOICE = 'multiple_choice'
const MULTIPLE_CHOICE_MORE = 'multiple_choice_more'
const NULLIFIED = 'nullified'
const OPEN_RESPONSE = 'open_response'
const OPEN_RESPONSE_SUBITEMS = 'open_response_subitem'
const RIGHT = 'right'
const UNAVAILABLE = 'unavailable'
const WRONG = 'wrong'

export {
  ANSWERED,
  BLANK,
  CORRECT,
  ESSAY,
  FINISHED,
  SELECTED,
  MULTIPLE_CHOICE,
  MULTIPLE_CHOICE_MORE,
  NULLIFIED,
  OPEN_RESPONSE,
  OPEN_RESPONSE_SUBITEMS,
  RIGHT,
  UNAVAILABLE,
  WRONG,
}
