import React from 'react'
import PropTypes from 'prop-types'

import { EmptyState, Text } from '@plurall/elo-beta'

import styles from './ErrorMessage.module.scss'

const ErrorMessage = ({ padding, subTitle, title, dataTestId }) => (
  <div style={{ padding }} className={styles['no-results']} data-test-id={dataTestId}>
    <EmptyState variation='worried' bg='mask' title={title}>
      {subTitle ? (
        <Text dimmed size='t3' alignment='center'>
          {subTitle}
        </Text>
      ) : (
        <></>
      )}
    </EmptyState>
  </div>
)

ErrorMessage.defaultProps = {
  title: 'Corremos e voamos, mas não encontramos o que você busca.',
}

ErrorMessage.propTypes = {
  padding: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  dataTestId: PropTypes.string,
}

export default ErrorMessage
