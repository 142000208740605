const filterPresenter = filters => {
  const normalizedFilters = filters.values.map(filter => ({
    id: filter.id,
    label: filter.value,
    checked: false,
  }))

  return [{ label: filters.name, value: '', checked: true }, ...normalizedFilters]
}

export default filterPresenter
