import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Route, useHistory } from 'react-router-dom'
import { ControlGroupItem, BookCover } from '@plurall/elo-beta'
import hacParams from 'utils/hacParams'
import styles from './InputDropdownCustom.module.css'

const DropdownItem = ({ item, index, dataTestId, scrollbarWrapperHeight }) => {
  const history = useHistory()
  const { queryParameters } = hacParams()
  const [displayImg, setDisplayImg] = useState(true)
  const itemKey = item.id || item.label
  const itemDataTestId = `${dataTestId}-${index + 1}`

  return (
    <Route
      render={() => (
        <div className={styles.ControlGroupItem}>
          <ControlGroupItem
            dataTestId={itemDataTestId}
            key={itemKey}
            onClick={() => history.push(`/material/${item.id}/${queryParameters}`)}
            disabled={item.disabled}
            scrollbarWrapperHeight={scrollbarWrapperHeight}
            role='option'
            ariaSelected={item.checked}
            selected={item.checked}
          >
            <div className={styles.itemDropDown}>
              <div className={styles.itemCover}>
                {item.thumbnail && displayImg ? (
                  <img
                    src={item.thumbnail}
                    alt={`Capa do material ${item.label}`}
                    onError={() => {
                      setDisplayImg(false)
                    }}
                  />
                ) : (
                  <div className={styles.bookCover}>
                    <BookCover height='52px' width='43px' />
                  </div>
                )}
              </div>
              <div className={styles.itemLabel}> {item.label}</div>
            </div>
          </ControlGroupItem>
        </div>
      )}
    />
  )
}

DropdownItem.propTypes = {
  index: PropTypes.number,
  item: PropTypes.shape({
    checked: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    thumbnail: PropTypes.string,
    groupTitle: PropTypes.string,
    group: PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  }),
  dataTestId: PropTypes.string,
  scrollbarWrapperHeight: PropTypes.string,
}

export default DropdownItem
