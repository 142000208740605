import React from 'react'
import PropTypes from 'prop-types'

import { Text } from '@plurall/elo-beta'

const Essay = ({ typeInWords }) => (
  <Text dimmed alignment='center' textTransform='capitalize'>
    {typeInWords}
  </Text>
)

Essay.propTypes = {
  typeInWords: PropTypes.string.isRequired,
}

export default Essay
