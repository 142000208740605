import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import { getToken } from 'utils/token'
import { getOauthClient } from 'utils/oauth'

const handleNotAuthenticated = url => {
  const OAuth = getOauthClient(url)
  window.location.href = OAuth.token.getUri()
  return null
}

const PrivateRoute = ({ component: Component, reportContext, ...rest }) => {
  const getPath = propsFromRoute => {
    const path = propsFromRoute?.match?.path
    return path && path !== 'undefined' ? path : '/'
  }
  return (
    <Route
      {...rest}
      render={props =>
        getToken() ? <Component {...props} /> : handleNotAuthenticated(getPath(props))
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  reportContext: PropTypes.object,
}

export default PrivateRoute
