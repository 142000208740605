import { DOUBTS, VIDEO_EXTERNAL } from 'App/constants/supportMaterialTypes'

const normalizeHelpOptions = options => {
  const doubtsLink = options.find(link => link.key === DOUBTS)
  const supportLinks = options.filter(link => link.key !== DOUBTS)

  const externalVideo = supportLinks.find(link => link.key === VIDEO_EXTERNAL)
  const remaining = supportLinks.filter(
    link => link.key !== VIDEO_EXTERNAL && link.url !== 'disabled',
  )

  const buildMediaLinks = () => {
    if (externalVideo) return [externalVideo, ...remaining]
    if (!externalVideo && remaining.length === 0) return undefined
    return remaining
  }

  return {
    doubtsLink,
    mediaLinks: buildMediaLinks(),
  }
}

export { normalizeHelpOptions }
