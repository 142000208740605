import React from 'react'
import PropTypes from 'prop-types'

import { Icon, Text } from '@plurall/elo-beta'

import styles from './Paginator.module.css'

const Paginator = ({ current, paginateCallback, total }) => {
  const isFirst = current === 0
  const isLast = current === total - 1

  const prev = () => paginateCallback(current - 1)
  const next = () => paginateCallback(current + 1)

  return (
    <div className={styles.paginator} data-test-id='paginator'>
      <div
        onClick={!isFirst ? prev : null}
        className={` ${styles['arrow-wrapper']} ${isFirst ? styles.disabled : ''}`}
      >
        <div className={styles.icon}>
          <Icon type='Chevron-left' variant='brand' />
        </div>

        <div className={` ${styles['text-wrapper']} ${styles.previous}`}>
          <Text size='t3' secondary textTransform='uppercase'>
            anterior
          </Text>
        </div>
      </div>

      <div
        onClick={!isLast ? next : null}
        className={`${styles['arrow-wrapper']} ${isLast ? styles.disabled : ''}`}
      >
        <div className={` ${styles['text-wrapper']} ${styles.next}`}>
          <Text size='t3' secondary textTransform='uppercase'>
            próxima
          </Text>
        </div>

        <div className={styles.icon}>
          <Icon type='Chevron-right' variant='brand' />
        </div>
      </div>
    </div>
  )
}

Paginator.propTypes = {
  current: PropTypes.number.isRequired,
  paginateCallback: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
}

export default Paginator
