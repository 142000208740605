import React, { useState, useEffect } from 'react'
import { Checkbox, Dialog, Text, Heading } from '@plurall/elo-beta'
import { getFeature, FEATURE_BANNER_SURVEY_MONKEY } from 'utils/featureToggles'

import styles from './BannerSurvey.module.css'

export const ROLE_ADMIN_ID = 1
export const ROLE_TEACHER_ID = 3
export const ROLE_COORDINATOR_ID = 4
export const ROLE_DIRECTOR_ID = 5
export const ROLE_STUDENT_ID = 6
export const ROLE_MAINTAINER_ID = 18

const BannerSurvey = ({ person }) => {
  const [showBannerTop, setShowBannerTop] = useState(true)
  const [enabledDialogSurvey, setEnabledDialogSurvey] = useState(false)
  const [userIsStudent, setUserIsStudent] = useState(false)
  const [paramsUrlSurveyMonkey, setParamsUrlSurveyMonkey] = useState()
  const [surveyData, setSurveyData] = useState()
  const [roleSelected, setRoleSelected] = useState()
  const [linkForSurveyMoney, setLinkForSurveyMoney] = useState()

  const closeDialog = e => {
    e.preventDefault()
    setShowBannerTop(false)
    setEnabledDialogSurvey(true)
    window.PLURALL_ELO.bannerTopShow(false)
  }

  const openBanner = data => {
    window.PLURALL_ELO.bannerTopShow(showBannerTop, {
      text: data.text,
      linkText: data.link_text,
      variant: 'informative',
      target: '_blank',
      onClick: e => closeDialog(e),
    })
  }

  const hasRole = roleID =>
    person && person?.roles && person?.roles?.find(role => Number(role.id) === roleID)

  const initBanner = async () => {
    const banner = await getFeature(FEATURE_BANNER_SURVEY_MONKEY)
    if (banner) {
      const { enabled, student, staff, links } = banner
      if (enabled && person) {
        const isStudent = hasRole(ROLE_STUDENT_ID) && hasRole(ROLE_STUDENT_ID)
        setUserIsStudent(isStudent)

        setParamsUrlSurveyMonkey(`?somosid=${person?.id}`)
        let link = links.students
        if (roleSelected && roleSelected === 'responsible') {
          link = links.responsibles
        }

        const userIsTeacher = hasRole(ROLE_TEACHER_ID) && hasRole(ROLE_TEACHER_ID)
        if (userIsTeacher) {
          link = links.teachers
        }

        const userIsStaffMember =
          (hasRole(ROLE_COORDINATOR_ID) && hasRole(ROLE_COORDINATOR_ID)) ||
          (hasRole(ROLE_DIRECTOR_ID) && hasRole(ROLE_DIRECTOR_ID)) ||
          (hasRole(ROLE_ADMIN_ID) && hasRole(ROLE_ADMIN_ID))

        if (userIsStaffMember) {
          link = links.staff
        }

        setLinkForSurveyMoney(link)

        if (userIsStaffMember || userIsTeacher) {
          setUserIsStudent(false)
          openBanner(staff.banner)
          setSurveyData(staff.dialog)
          return
        }

        if (isStudent && person?.roles?.find(role => role.classes?.length)) {
          openBanner(student.banner)
          setSurveyData(student.dialog)
        }
      }
    }
  }

  useEffect(() => {
    initBanner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person])

  useEffect(() => {
    initBanner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleSelected])

  if (!enabledDialogSurvey) {
    return <></>
  }

  return (
    <Dialog
      clickOutside={false}
      title={surveyData.title}
      primaryButtonText={surveyData.button}
      primaryButtonDisabled={!roleSelected && userIsStudent}
      onPrimaryClick={() => {
        window.open(`${linkForSurveyMoney}${paramsUrlSurveyMonkey}`, '_blank')
      }}
    >
      <Text>
        <span dangerouslySetInnerHTML={{ __html: surveyData.text }} />
      </Text>
      <br />
      <br />

      {userIsStudent && (
        <div>
          <Heading element='h6'>Você é?</Heading>
          <div className={styles['checkboxes-dialog-banner']}>
            <Checkbox
              checked={roleSelected === 'student'}
              label='Aluno'
              name='checkbox-student'
              onChange={() => setRoleSelected('student')}
              type='radio'
              dataTestId='banner-checkbox-student'
            />
            <Checkbox
              checked={roleSelected === 'responsible'}
              label='Responsável'
              name='checkbox-responsible'
              onChange={() => setRoleSelected('responsible')}
              type='radio'
              dataTestId='banner-checkbox-responsible'
            />
          </div>
        </div>
      )}
    </Dialog>
  )
}

export default BannerSurvey
