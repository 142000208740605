import React from 'react'
import PropTypes from 'prop-types'

import { Spinner, Text } from '@plurall/elo-beta'
import styles from './Loading.module.css'
import Wrapper from './Wrapper'

const Loading = ({ padding, small, text }) => (
  <Wrapper padding={padding}>
    <div className={styles.spinner}>
      <Spinner size={small ? 'small' : 'large'} />
    </div>

    <div className={styles.spinner}>
      <Text size='t1' semibold alignment='center'>
        Carregando...
      </Text>
      {text && (
        <Text dimmed size='t3' alignment='center'>
          Aguarde enquanto carregamos {text}
        </Text>
      )}
    </div>
  </Wrapper>
)

Loading.propTypes = {
  padding: PropTypes.string,
  small: PropTypes.bool,
  text: PropTypes.string,
}

export default Loading
