import React from 'react'
import PropTypes from 'prop-types'

import { Button, Input, InputImage, Text } from '@plurall/elo-beta'

import { ESSAY } from 'App/constants/exerciseStatus'

import Answer from './Answer'

import styles from './OpenResponse.module.scss'

const renderUserAnswers = ({ answer, image }) => (
  <Answer answer={answer} image={image} key={answer} title='Resposta' />
)

renderUserAnswers.propTypes = {
  answer: PropTypes.string,
  image: PropTypes.string,
}

const OpenResponse = ({
  answers,
  answerType,
  file,
  message,
  messageLength,
  officialAnswer,
  onChange,
  onFileChange,
  resolutionVideo,
  sendAnswerEnabled,
  onSubmitButtonClick,
  questionType,
}) => {
  const isEssay = questionType === ESSAY
  const uploadText = `${isEssay ? 'E' : 'Ou e'}nvie uma foto da sua resposta! Selecione um arquivo`
  const hasUserAnswer = answers.length > 0
  let disableTextArea = !!file
  let disableFileUpload = !!message

  if (answerType === 'image') {
    disableTextArea = true
  }

  if (answerType === 'text') {
    disableFileUpload = true
  }

  return (
    <React.Fragment>
      {hasUserAnswer && answers.map(renderUserAnswers)}

      {officialAnswer && (
        <Answer answer={officialAnswer} resolutionVideo={resolutionVideo} isPurple />
      )}

      {sendAnswerEnabled && (
        <div className={styles.wrapper}>
          {!isEssay && (
            <div className={styles['input-container']}>
              <div className={styles['char-count']}>
                <Text size='t4' dimmed>
                  Caracteres: {messageLength}
                </Text>
              </div>

              <Input
                dataTestId='response-textarea'
                type='textarea'
                placeholder='Digite sua resposta'
                onChange={onChange}
                disabled={disableTextArea}
              />

              <div className={styles['minimum-char']}>
                <Text size='t4' dimmed>
                  Mínimo de 20 caracteres
                </Text>
              </div>
            </div>
          )}

          {!disableFileUpload && (
            <InputImage dataTestId='input-image-id' title={uploadText} onChange={onFileChange} />
          )}

          <div className={styles.button}>
            <Button onClick={onSubmitButtonClick} disabled={!message && !file}>
              <Text size='t2' bold>
                Enviar resposta
              </Text>
            </Button>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

OpenResponse.propTypes = {
  answers: PropTypes.array,
  answerType: PropTypes.string,
  file: PropTypes.string,
  message: PropTypes.string,
  officialAnswer: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFileChange: PropTypes.func.isRequired,
  resolutionVideo: PropTypes.string,
  sendAnswerEnabled: PropTypes.bool,
  onSubmitButtonClick: PropTypes.func.isRequired,
  questionType: PropTypes.string,
}

export default OpenResponse
