const DOCUMENT = 'document'
const DOUBTS = 'create_doubt'
const FILE = 'file'
const TEST_FILE = 'test_file'
const VIDEO = 'video'
const VIDEO_EXTERNAL = 'video_external'
const VIDEO_EXTERNAL_ALT = 'video_external_alt2'
const VIDEO_RESOLUTION = 'video_external_res'

export {
  DOCUMENT,
  DOUBTS,
  FILE,
  TEST_FILE,
  VIDEO,
  VIDEO_EXTERNAL,
  VIDEO_EXTERNAL_ALT,
  VIDEO_RESOLUTION,
}
