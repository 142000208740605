import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Route, useHistory } from 'react-router-dom'

import StatusInWords from 'App/components/StatusInWords'

import { Alert, BookCover, Card, Icon, Text, Tooltip } from '@plurall/elo-beta'

import hacParams from 'utils/hacParams'

import styles from './MaterialCard.module.scss'

const MaterialCard = ({ index, material, clickedSuspended }) => {
  const history = useHistory()
  const { queryParameters } = hacParams()
  const [displayImg, setDisplayImg] = useState(true)
  const [showMoreButton, setShowMoreButton] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const hiddenTextRef = useRef(null)
  const LINE_HEIGHT = 24

  useEffect(() => {
    if (hiddenTextRef.current) {
      if (hiddenTextRef.current.clientHeight > 2 * LINE_HEIGHT) {
        setShowMoreButton(true)
      } else {
        setShowMoreButton(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenTextRef.current])

  return (
    <Route
      render={() => (
        <div>
          <Card>
            <div className={styles.holder}>
              <div
                className={
                  material.available
                    ? styles['material-cover']
                    : `${styles['material-cover']} ${styles.unavailable}`
                }
              >
                {material.thumbnail && displayImg ? (
                  <img
                    src={material.thumbnail}
                    alt={`Capa do material ${material.value}`}
                    onError={() => {
                      setDisplayImg(false)
                    }}
                  />
                ) : (
                  <BookCover margin-left='-8px' height='100px' width='71px' />
                )}
              </div>

              <div className={styles['material-info']} id={`material-info-${index + 1}`}>
                <Tooltip title={material.value}>
                  <div className={styles.text}>
                    <Text
                      className={showMore ? styles['full-title'] : styles.title}
                      semibold
                      size='t3'
                    >
                      {material.value}
                    </Text>
                    {showMoreButton && (
                      <Text
                        element='a'
                        size='t3'
                        noUnderline
                        display='inline'
                        onClick={() => setShowMore(prev => !prev)}
                      >
                        {showMore ? 'Ler menos' : '...ler mais'}
                      </Text>
                    )}
                    <div ref={hiddenTextRef} className={styles.hidden}>
                      {material.value}
                    </div>
                  </div>
                </Tooltip>
                <div className={styles.available}>
                  {material.statusInWords && (
                    <StatusInWords
                      icon={
                        material.available ? <Icon type='Calendar' /> : <Icon type='Lock' dimmed />
                      }
                      status={material.statusInWords}
                      iconMarginTop='-2px'
                      size='t4'
                    />
                  )}
                </div>
                {material.accessStatus && material.accessStatus === 'suspended' && (
                  <div className={styles['banner-suspendend']}>
                    <div>
                      <Icon type='Cancel-circle' />
                    </div>
                    <div>
                      <Text variant='negative' size='t4'>
                        Acesso suspenso
                      </Text>
                    </div>
                  </div>
                )}
                <div className={styles['button-wrapper']}>
                  <Text
                    size='t4'
                    semibold
                    noUnderline
                    element='a'
                    onClick={() =>
                      material.accessStatus === 'suspended'
                        ? clickedSuspended()
                        : history.push(`/material/${material.id}/${queryParameters}`)
                    }
                  >
                    Acessar
                  </Text>
                </div>
              </div>
            </div>
          </Card>
        </div>
      )}
    />
  )
}

const MaterialPageCard = ({ index, material }) => {
  const [showAlert, setShowAlert] = useState(false)

  return (
    <React.Fragment>
      {showAlert && (
        <Alert
          onlyConfirm
          primaryButtonText='Fechar'
          title='Material com acesso suspenso'
          variant='negative'
          onPrimaryClick={() => setShowAlert(false)}
        >
          Entre em contato com a secretaria da sua escola para normalizar o acesso.
        </Alert>
      )}

      <MaterialCard index={index} material={material} clickedSuspended={() => setShowAlert(true)} />
    </React.Fragment>
  )
}

const defaultPropTypes = {
  index: PropTypes.number.isRequired,
  material: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    accessStatus: PropTypes.string,
  }).isRequired,
}

MaterialCard.propTypes = {
  ...defaultPropTypes,
  clickedSuspended: PropTypes.func,
}

MaterialPageCard.propTypes = {
  ...defaultPropTypes,
}

export default MaterialPageCard
