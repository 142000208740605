import classNames from 'classnames/bind'
import React from 'react'
import PropTypes from 'prop-types'

import { Button, Text } from '@plurall/elo-beta'

import Wrapper from 'App/components/Wrapper'

import Timer from 'App/components/Timer'
import Exercises from 'App/views/Task/components/Exercises'
import MediaTasks from 'App/views/Task/components/MediaTasks'
import Loading from 'App/components/Loading'
import TaskAction from 'App/views/Task/components/TaskAction'

import styles from './TaskContainer.module.scss'

const cx = classNames.bind(styles)

class TaskContainer extends React.Component {
  static propTypes = {
    exercises: PropTypes.array,
    handleTaskSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
    loading: PropTypes.bool,
    mediaTasks: PropTypes.array,
    onMediaTaskClick: PropTypes.func.isRequired,
    task: PropTypes.object,
  }

  renderMediaTasks = () => (
    <MediaTasks
      mediaTasks={this.props.mediaTasks}
      officialAnswerInWords={this.props.task.officialAnswerInWords}
      onTaskClick={this.props.onMediaTaskClick}
      statusInWords={this.props.task.statusInWords}
      video={this.props.task.video}
    />
  )

  renderExercises = () => (
    <Exercises activityStatus={this.props.task.status} exercises={this.props.exercises} />
  )

  render() {
    const { exercises, handleTaskSubmit, isSubmitting, loading, mediaTasks, task } = this.props
    const taskDetailClasses = cx(
      'task-detail',
      { 'task-detail-height': loading },
      { 'task-detail-submitting': isSubmitting },
    )
    return (
      <Wrapper padding='20px 0'>
        <div className={taskDetailClasses}>
          {loading ? (
            <Loading padding='120px 0 0 0' />
          ) : (
            <React.Fragment>
              {task && task.endDate && <Timer endDate={task.endDate} />}

              {(task.statusInWords || mediaTasks.length > 0) && this.renderMediaTasks()}

              {exercises.length > 0 && this.renderExercises()}

              {task.receiptLink && (
                <TaskAction
                  renderButton={disabled => (
                    <Button disabled={disabled}>
                      <Text bold>{task.receiptLink.title}</Text>
                    </Button>
                  )}
                  url={task.receiptLink.url}
                />
              )}

              {task.finishTaskInWords && (
                <TaskAction
                  renderButton={() => (
                    <Button disabled={isSubmitting} onClick={handleTaskSubmit}>
                      <Text bold>{task.finishTaskInWords}</Text>
                    </Button>
                  )}
                />
              )}
            </React.Fragment>
          )}
        </div>
      </Wrapper>
    )
  }
}

export default TaskContainer
