import React from 'react'

import Option from 'App/components/Option'
import QuickTaskPresenter from 'App/presenters/QuickTaskPresenter'

const MultipleChoice = ({ answers, onClick, subtask, taskId }) =>
  subtask.options.map(option => {
    const presenter = new QuickTaskPresenter({
      answers,
      option,
      subtask,
    })

    const { canBeAnswered, buildOption } = presenter

    const handleOptionSelection = () => {
      if (!canBeAnswered()) {
        return null
      }

      return onClick({ option, subtask, taskId })
    }

    return (
      <Option
        key={`${subtask.id}-${option.sequence}`}
        onClick={handleOptionSelection}
        sequence={option.sequence}
        {...buildOption()}
      />
    )
  })

export default MultipleChoice
