import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import Client from 'utils/client'
import { getUserName } from 'utils/getUserName'
import { TitleContext } from 'utils/context'
import hacParams from 'utils/hacParams'

import SubHeader from 'App/components/SubHeader'

import TaskContainer from './containers/TaskContainer'

import TaskMaterial from './components/TaskMaterial'
import TaskExercise from '../TaskExercise'

class Task extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        taskId: PropTypes.string.isRequired,
      }),
    }),
    onListUpdated: PropTypes.func.isRequired,
  }

  state = { loading: true, url: '' }

  componentDidMount() {
    this.fetchTask()
  }

  onMediaTaskClick = ({ status, url }) => this.setState({ status, url })

  getSubtaskGroup = type =>
    this.state.task && this.state.task.subtaskGroups.filter(({ layoutType }) => layoutType === type)

  handleTaskSubmit = () => {
    const submitClient = new Client({
      accept: 'vnd.plurall.api.v1+json',
    })

    this.setState(
      {
        isSubmitting: true,
      },
      async () => {
        await submitClient.getPlurallClient().submitTask({
          userTaskId: this.state.task.userTaskId,
        })

        await this.fetchTask()

        this.setState({
          isSubmitting: false,
        })
      },
    )
  }

  fetchTask = async () => {
    const {
      data: { data: task },
    } = await this.client.getTask({
      taskId: this.props.match.params.taskId,
    })

    this.setState({
      loading: false,
      task,
    })
  }

  client = new Client()

  render() {
    const {
      fetchTask,
      props: {
        match: { path },
        name: taskListName,
        onListUpdated,
      },
      state: { isSubmitting, loading, url, status, task },
    } = this

    const { queryParameters } = hacParams()

    return (
      <React.Fragment>
        <Route
          path={`${path}/exercicio/:exerciseId/`}
          render={props => {
            if (!task) {
              return null
            }

            const [exercises] = this.getSubtaskGroup('grid')

            return (
              <TaskExercise
                {...props}
                exercises={exercises}
                task={task}
                onListUpdated={onListUpdated}
                updateTask={fetchTask}
              />
            )
          }}
        />

        <Route
          path={`${path}/(leitura|video)`}
          render={() => (
            <TaskMaterial
              fetchTask={fetchTask}
              onListUpdated={onListUpdated}
              subTitle={taskListName}
              task={task}
              status={status}
              url={url}
            />
          )}
        />

        <Route
          exact
          path='/material/:materialId/aula/:lessonId/tarefa/:taskId'
          render={({ match: { params } }) => {
            const exercises = this.getSubtaskGroup('grid')
            const mediaTasks = this.getSubtaskGroup('list')

            return (
              <div id='task'>
                <TitleContext.Consumer>
                  {data => (
                    <SubHeader
                      subTitle={taskListName && `${data.taskListTitle} / ${taskListName}`}
                      title={task && task.name}
                      user={getUserName(data.userProfile)}
                      backTo={`/material/${params.materialId}/${queryParameters}`}
                    />
                  )}
                </TitleContext.Consumer>

                <TaskContainer
                  exercises={exercises}
                  handleTaskSubmit={this.handleTaskSubmit}
                  isSubmitting={isSubmitting}
                  loading={loading}
                  mediaTasks={mediaTasks}
                  onMediaTaskClick={this.onMediaTaskClick}
                  task={task}
                />
              </div>
            )
          }}
        />
      </React.Fragment>
    )
  }
}

export default Task
