import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import { TitleContext } from 'utils/context'
import { getUserName } from 'utils/getUserName'
import { Card } from '@plurall/elo-beta'

import SubHeader from 'App/components/SubHeader'
import Wrapper from 'App/components/Wrapper'

import hacParams from 'utils/hacParams'

import styles from './index.module.scss'

const MaterialSupport = ({ exercise, task, url }) => (
  <Route
    render={({ match: { params } }) => {
      const { queryParameters } = hacParams()
      return (
        <div id='exercise'>
          <TitleContext.Consumer>
            {data => (
              <SubHeader
                title={exercise && task && `${task.name} - Exercício ${exercise && exercise.title}`}
                subTitle={
                  data.taskListTitle &&
                  `${data.taskListTitle} / ${task.subtitle} ${task.title ? `/ ${task.title}` : ''}`
                }
                user={getUserName(data.userProfile)}
                backTo={`/material/${params.materialId}/aula/${params.lessonId}/tarefa/${params.taskId}/exercicio/${params.exerciseId}/${queryParameters}`}
              />
            )}
          </TitleContext.Consumer>

          <Wrapper padding='20px 0 0 0'>
            <Card>
              <div className={styles['task-material-container']}>
                <iframe src={url} title='Material' allowFullScreen />
              </div>
            </Card>
          </Wrapper>
        </div>
      )
    }}
  />
)

MaterialSupport.propTypes = {
  url: PropTypes.string.isRequired,
  task: PropTypes.object.isRequired,
  exercise: PropTypes.object.isRequired,
}

export default MaterialSupport
