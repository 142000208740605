import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Text } from '@plurall/elo-beta'

import styles from './CheckActivity.module.scss'

const CheckActivity = ({ activityPathname }) => (
  <div className={styles['check-lesson']}>
    <Link to={activityPathname}>
      <Text size='t3' noUnderline element='a' alignment='end'>
        Visualizar atividade
      </Text>
    </Link>
  </div>
)

CheckActivity.propTypes = {
  activityPathname: PropTypes.string.isRequired,
}

export default CheckActivity
