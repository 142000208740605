import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'

import { Header, Icon, Text, Input } from '@plurall/elo-beta'

import Wrapper from 'App/components/Wrapper'

import InputDropdownCustom from './InputDropdownCustom'

import styles from './SubHeader.module.css'

const renderUserInfo = user => (
  <Wrapper>
    <div className={styles['user-info']} data-test-id='sub-header-user-info'>
      <div className={styles.icon}>
        <Icon type='User-male' shy />
      </div>

      <Text size='t3' semibold>
        Usuário logado:
      </Text>

      <Text size='t3' dimmed>
        {user}
      </Text>
    </div>
  </Wrapper>
)

const SubHeader = ({
  backTo,
  title,
  subTitle,
  user,
  isMaterialList,
  materialSearch,
  onSearch,
  onShowAllSearchClick,
  tab,
}) => {
  const history = useHistory()
  return (
    <React.Fragment>
      <div className={styles.wrapper}>
        <div className={styles['wrapper-header']} data-test-id='sub-header'>
          <Header description={subTitle} backButton={!!backTo} onClick={() => history.push(backTo)}>
            {title}
          </Header>
        </div>
        {isMaterialList && tab === 0 && (
          <div className={styles['wrapper-input']}>
            <Input
              type='text'
              placeholder='Buscar por cadernos ou listas'
              icon='search'
              onChange={onSearch}
            />
          </div>
        )}
        {isMaterialList && tab !== 0 && (
          <div className={styles['wrapper-input']}>
            <InputDropdownCustom
              placeholder='Buscar por cadernos ou listas'
              onSearchChange={onSearch}
              categorized
              items={materialSearch}
              footerText='Mostrar todos os resultados'
              onFooterClick={onShowAllSearchClick}
              inlineEdit={false}
            />
          </div>
        )}
      </div>

      {user && !isMaterialList && renderUserInfo(user)}
    </React.Fragment>
  )
}

SubHeader.defaultProps = {
  title: '',
  subTitle: '',
}

SubHeader.propTypes = {
  backTo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.object,
    }),
  ]),
  subTitle: PropTypes.string,
  title: PropTypes.string,
  user: PropTypes.string,
  onSearch: PropTypes.func,
  onShowAllSearchClick: PropTypes.func,
  materialSearch: PropTypes.object,
}

export default SubHeader
