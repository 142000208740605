import React from 'react'

import { Icon } from '@plurall/elo-beta'

class QuickTaskPresenter {
  constructor({ answers, option, subtask }) {
    this.answers = answers
    this.subtask = subtask
    this.option = option
  }

  canBeAnswered = () => !this.subtask.status || this.subtask.status === 'blank'

  isAnsweredAndRight = () => {
    const { answer, status: userOptionStatus } = this.subtask

    return (
      userOptionStatus === 'correct' && answer.toUpperCase() === this.option.sequence.toUpperCase()
    )
  }

  isAnsweredAndWrong = () => {
    const { answer, status: userOptionStatus } = this.subtask

    return (
      userOptionStatus === 'wrong' && answer.toUpperCase() === this.option.sequence.toUpperCase()
    )
  }

  isCorrectAnswer = () => {
    const { status: userOptionStatus } = this.subtask

    return userOptionStatus === 'wrong' && this.option.correct
  }

  subtaskItemIsAnswered = () =>
    this.answers.find(({ subtasks }) => subtasks.find(({ id }) => id === this.subtask.id))

  mapStatus = () => {
    const isAnswered = this.subtaskItemIsAnswered()

    const isSelectedAnswer =
      isAnswered &&
      isAnswered.subtasks.find(
        ({ answer, id }) => id === this.subtask.id && answer === this.option.sequence,
      )

    if (!this.canBeAnswered()) {
      if (this.isAnsweredAndWrong()) {
        return { optionClass: 'wrong' }
      }

      if (this.isCorrectAnswer()) {
        return { optionClass: 'correct' }
      }

      if (this.isAnsweredAndRight()) {
        return { optionClass: 'right' }
      }
    }

    return {
      optionClass: isSelectedAnswer ? 'answered' : '',
      bold: isSelectedAnswer,
      isWhite: isSelectedAnswer,
    }
  }

  buildOption = () => ({
    isAnswered: this.subtaskItemIsAnswered(),
    ...this.mapStatus(),
    rightAnswerIcon: (
      <div
        style={{
          color:
            this.mapStatus().optionClass === 'correct'
              ? 'var(--color-mint30)'
              : 'var(--color-white)',
        }}
      >
        <Icon type='Check' size='big' />
      </div>
    ),
    wrongAnswerIcon: (
      <div style={{ color: 'var(--color-white)' }}>
        <Icon type='Cancel' size='big' />
      </div>
    ),
  })
}

export default QuickTaskPresenter
