import classNames from 'classnames/bind'
import React from 'react'
import PropTypes from 'prop-types'

import { Button, Icon } from '@plurall/elo-beta'

import Option from 'App/components/Option'

import {
  ANSWERED,
  CORRECT,
  RIGHT,
  SELECTED,
  MULTIPLE_CHOICE_MORE,
} from 'App/constants/exerciseStatus'

import Answer from './Answer'

import styles from './MultipleChoice.module.scss'

const cx = classNames.bind(styles)

const onClickSubmit = (
  submitAnswer,
  sendAnswerEnabled,
  optionClass,
  sequence,
  questionType,
  joinAnswers,
) => {
  if (sendAnswerEnabled && questionType === MULTIPLE_CHOICE_MORE) {
    return joinAnswers(sequence)
  }

  if (sendAnswerEnabled && !optionClass) {
    return submitAnswer(sequence, questionType)
  }

  return null
}

const OptionWrapper = ({
  optionClass,
  sendAnswerEnabled,
  sequence,
  submitAnswer,
  text,
  questionType,
  joinAnswers,
}) => (
  <li className='option'>
    <Option
      onClick={() =>
        onClickSubmit(
          submitAnswer,
          sendAnswerEnabled,
          optionClass,
          sequence,
          questionType,
          joinAnswers,
        )
      }
      optionClass={optionClass}
      bold
      isPurple
      isWhite={optionClass === ANSWERED || optionClass === SELECTED}
      rightAnswerIcon={
        <div
          style={{
            color: optionClass === RIGHT ? 'var(--color-white)' : 'var(--color-mint30)',
          }}
        >
          <Icon type='Check' size='big' />
        </div>
      }
      wrongAnswerIcon={
        <div style={{ color: 'var(--color-white)' }}>
          <Icon type='Cancel' size='big' />
        </div>
      }
      sequence={sequence}
      sendAnswerEnabled={sendAnswerEnabled}
    />

    <span className={styles['option-text']} dangerouslySetInnerHTML={{ __html: text }} />
  </li>
)

OptionWrapper.propTypes = {
  optionClass: PropTypes.string,
  sendAnswerEnabled: PropTypes.bool.isRequired,
  sequence: PropTypes.string.isRequired,
  submitAnswer: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}

const getOptionClass = ({ isCorrect, isUserAnswer, isSelected }) => {
  if (isSelected) {
    return SELECTED
  }

  if (isCorrect) return CORRECT

  if (isUserAnswer) {
    const { status } = isUserAnswer

    return status === CORRECT ? RIGHT : status
  }

  return ''
}

const renderOption = ({
  answerRightOption,
  answers,
  submitAnswer,
  sendAnswerEnabled,
  sequence,
  text,
  questionType,
  userAnswer,
  joinAnswers,
}) => {
  const userAnswers = answers.map(({ answer, status }) => ({
    answer: answer.split('/').map(letter => letter.toUpperCase()),
    status,
  }))
  userAnswers.reverse()
  const isUserAnswer = userAnswers.find(
    ({ answer }) => answer.length !== 0 && answer.includes(sequence.toUpperCase()),
  )

  const isCorrect = answerRightOption?.includes(sequence)

  return (
    <OptionWrapper
      key={sequence}
      optionClass={getOptionClass({
        isCorrect,
        isUserAnswer,
        isSelected: userAnswer?.includes(sequence),
      })}
      sendAnswerEnabled={sendAnswerEnabled}
      sequence={sequence}
      submitAnswer={submitAnswer}
      text={text}
      questionType={questionType}
      joinAnswers={joinAnswers}
    />
  )
}

renderOption.propTypes = {
  answerRightOption: PropTypes.string,
  answers: PropTypes.array,
  sendAnswerEnabled: PropTypes.bool.isRequired,
  sequence: PropTypes.string.isRequired,
  submitAnswer: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}

const MultipleChoice = ({
  answers,
  question: { answerRightOption, answerText, options, resolutionVideo },
  sendAnswerEnabled,
  submitAnswer,
  questionType,
  userAnswer,
  joinAnswers,
}) => {
  const hasOfficialAnswer = answerText || resolutionVideo
  const ulClasses = cx('multiple-choice', { 'ul-margin-bottom': resolutionVideo })
  return (
    <React.Fragment>
      <div className={ulClasses}>
        <ul>
          {options.map(({ sequence, text }) =>
            renderOption({
              answerRightOption,
              answers,
              sendAnswerEnabled,
              sequence,
              submitAnswer,
              text,
              questionType,
              userAnswer,
              joinAnswers,
            }),
          )}
        </ul>
      </div>

      {hasOfficialAnswer && (
        <Answer answer={answerText} resolutionVideo={resolutionVideo} isPurple />
      )}

      {questionType === MULTIPLE_CHOICE_MORE && (
        <div className={styles['send-button']}>
          <Button
            disabled={userAnswer.length === 0}
            onClick={() => submitAnswer(userAnswer.join('/'), questionType)}
          >
            Enviar
          </Button>
        </div>
      )}
    </React.Fragment>
  )
}

MultipleChoice.propTypes = {
  answers: PropTypes.array,
  question: PropTypes.object.isRequired,
  sendAnswerEnabled: PropTypes.bool.isRequired,
  submitAnswer: PropTypes.func.isRequired,
}

export default MultipleChoice
