import classNames from 'classnames/bind'
import React from 'react'
import PropTypes from 'prop-types'
import { Link, Route } from 'react-router-dom'

import { Card, Icon, Text } from '@plurall/elo-beta'

import { FINISHED } from 'App/constants/exerciseStatus'

import StatusInWords from 'App/components/StatusInWords'
import hacParams from 'utils/hacParams'

import Progress from './Progress'

import styles from './ProgressGroup.module.scss'

const cx = classNames.bind(styles)

const ProgressGroup = ({
  index,
  lessonId,
  task: { id: taskId, name = '', progress, status, statusInWords },
  video,
}) => (
  <Route
    render={({ location: { pathname } }) => {
      const sectionNoMedia = cx('section', { media: video })
      const statusInWordClasses = cx('progress', { 'progress-margin-bottom': statusInWords })
      const { queryParameters } = hacParams()
      return (
        <React.Fragment>
          <section className={sectionNoMedia}>
            <Link
              to={`${pathname}${
                pathname.endsWith('/') ? '' : '/'
              }aula/${lessonId}/tarefa/${taskId}${queryParameters}`}
            >
              <Card interactive>
                <div className={styles.holder}>
                  <div style={{ width: '100%' }}>
                    <div className={styles['task-title']} id={`task-${index + 1}`}>
                      <Text semibold secondary>
                        {name}
                      </Text>
                    </div>
                    <div className={statusInWordClasses}>
                      <Progress {...progress} />
                    </div>
                  </div>

                  {statusInWords && (
                    <StatusInWords
                      icon={
                        status === FINISHED ? (
                          <Icon type='Lock' dimmed />
                        ) : (
                          <Icon type='Calendar' dimmed />
                        )
                      }
                      justifyContent='flex-end'
                      iconMarginTop='-4px'
                      status={statusInWords}
                    />
                  )}
                </div>
              </Card>
            </Link>
          </section>
        </React.Fragment>
      )
    }}
  />
)

const progressProps = PropTypes.shape({
  answered: PropTypes.number,
  blank: PropTypes.number,
  correct: PropTypes.number,
  nullified: PropTypes.number,
  unavailable: PropTypes.number,
  total: PropTypes.number,
  wrong: PropTypes.number,
  progress: PropTypes.number,
}).isRequired

const taskProps = PropTypes.shape({
  name: PropTypes.string.isRequired,
  progress: progressProps,
  id: PropTypes.number.isRequired,
})

ProgressGroup.propTypes = {
  index: PropTypes.number.isRequired,
  lessonId: PropTypes.string.isRequired,
  name: PropTypes.string,
  task: taskProps.isRequired,
  url: PropTypes.string,
}

export default ProgressGroup
