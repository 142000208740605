import React from 'react'
import PropTypes from 'prop-types'

import SubTask from './SubTask'
import SubtaskTitle from './SubtaskTitle'

const QuickTasks = ({ answers, onClick, tasks }) => (
  <div className='left'>
    {tasks.map(
      task =>
        task.subtasks.length > 0 && (
          <React.Fragment key={task.id}>
            <SubtaskTitle title={task.name} />

            {task.subtasks.map(subtask => (
              <SubTask
                answers={answers}
                key={subtask.id}
                onClick={onClick}
                subtask={subtask}
                taskId={task.id}
              />
            ))}
          </React.Fragment>
        ),
    )}
  </div>
)

QuickTasks.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      subtasks: PropTypes.array.isRequired,
      taskId: PropTypes.string.isRequired,
    }),
  ),
  onClick: PropTypes.func.isRequired,
  tasks: PropTypes.array.isRequired,
}

export default QuickTasks
