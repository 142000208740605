import React from 'react'
import PropTypes from 'prop-types'

import MediaContent from 'App/components/MediaContent'
import styles from './GridLayout.module.css'

const GridLayout = ({ children, media, onMediaClick, dataTestId }) => (
  <section
    className={`${styles.section} ${!media ? styles['no-media'] : ''}`}
    data-test-id={dataTestId}
  >
    {media && (
      <MediaContent
        onMediaClick={onMediaClick}
        thumbnail={media.thumbnail}
        title={media.title}
        url={media.url}
      />
    )}

    {children}
  </section>
)

const mediaProps = {
  thumbnail: PropTypes.string,
  url: PropTypes.string.isRequired,
}

GridLayout.propTypes = {
  children: PropTypes.node.isRequired,
  media: PropTypes.shape(mediaProps),
  onMediaClick: PropTypes.func,
  dataTestId: PropTypes.string,
}

export default GridLayout
