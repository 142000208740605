import classNames from 'classnames/bind'
import React from 'react'
import PropTypes from 'prop-types'

import { Card, Divider, Icon, Text } from '@plurall/elo-beta'

import Client from 'utils/client'
import { getUserName } from 'utils/getUserName'
import { TitleContext } from 'utils/context'
import { trackingEventNames } from 'App/constants/tracking'

import SubHeader from 'App/components/SubHeader'
import Wrapper from 'App/components/Wrapper'
import Loading from 'App/components/Loading'

import QuickTasks from './components/QuickTasks'
import TaskFeedback from './components/TaskFeedback'

import styles from './index.module.scss'

const cx = classNames.bind(styles)

class QuickAnswer extends React.PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    onListUpdated: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.client = new Client()
    this.discipline = ''
    this.lesson = ''
  }

  state = {
    answers: [],
    loading: true,
    quickTasks: {
      subtitle: '',
      title: '',
    },
  }

  async componentDidMount() {
    const {
      props: {
        match: {
          params: { lessonId },
        },
      },
    } = this
    const totalTask = this.getTotalParameters()

    const {
      data: { data: quickTasks, result },
    } = await this.client.getQuickTasks({
      lessonId,
      totalTask,
    })

    this.quickTasksTitle = quickTasks.subtitle
    this.quickTasksSubTitle = `${quickTasks.subtitle} - ${quickTasks.title}`

    if (result === 'success') {
      this.setState({
        answers: quickTasks.tasks.map(({ id }) => ({
          subtasks: [],
          taskId: id,
        })),
        loading: false,
        quickTasks,
      })
    }
  }

  getTotalParameters = () => {
    const params = new URLSearchParams(window.location.search)
    return params.get('total')
  }

  getPreviouslyAnswered = subtask => {
    const answerToBeChanged = ({ subtasks }) => subtasks.some(s => s.id === subtask.id)

    return this.state.answers.find(answerToBeChanged)
  }

  handleMultipleChoiceClick = ({ option, subtask, taskId }) => {
    this.setState(() => {
      const previouslyAnswered = this.getPreviouslyAnswered(subtask)

      const changeAnswers = answer =>
        answer.subtasks.map(s => (s.id === subtask.id ? { answer: option.sequence, id: s.id } : s))

      const updateAnswers = answer => [
        ...answer.subtasks,
        {
          id: subtask.id,
          answer: option.sequence,
        },
      ]

      const currentAnswers = answer => ({
        taskId,
        subtasks: previouslyAnswered ? changeAnswers(answer) : updateAnswers(answer),
      })

      const answers = this.state.answers.map(answer =>
        answer.taskId === taskId ? currentAnswers(answer) : answer,
      )

      return { answers }
    })
  }

  handleTasksSubmit = () => {
    const answersToSubmit = this.state.answers.filter(answer => answer.subtasks.length > 0)

    if (answersToSubmit.length > 0) {
      window.PLURALL_TRACKER.track(trackingEventNames.quickAnswer.quickAnswerSubmit)

      this.setState({ isSubmittingAnswer: true }, async () => {
        const {
          props: {
            match: {
              params: { lessonId },
            },
          },
        } = this
        const {
          data: { data: quickTasks },
        } = await this.client.submitQuickTasks({
          taskWorkflow: answersToSubmit,
          lessonId,
        })

        this.setState(
          {
            isSubmittingAnswer: false,
            quickTasks,
          },
          this.props.onListUpdated,
        )
      })
    }

    return null
  }

  isFinished = () => {
    const {
      quickTasks: { tasks },
    } = this.state

    const subtasks = tasks && tasks.map(task => task.subtasks).flat()

    const multipleChoiceSubtasks = subtasks.filter(({ type }) => type === 'multiple_choice')
    return multipleChoiceSubtasks.every(({ answer }) => !!answer)
  }

  render() {
    const {
      handleMultipleChoiceClick,
      handleTasksSubmit,
      isFinished,
      props: {
        match: {
          params: { materialId },
        },
      },
      state: { answers, isSubmittingAnswer, loading, quickTasks },
      quickTasksTitle,
      quickTasksSubTitle,
    } = this

    const submitAnswerClasses = cx(
      'quick-tasks',
      { submitting: isSubmittingAnswer },
      { height: loading },
    )
    return (
      <div id='task'>
        <React.Fragment>
          <TitleContext.Consumer>
            {data => (
              <SubHeader
                backTo={`/material/${materialId}`}
                subTitle={quickTasksSubTitle}
                title={quickTasksTitle}
                user={getUserName(data.userProfile)}
              />
            )}
          </TitleContext.Consumer>

          <Wrapper padding='20px 0 0 0'>
            <div className={submitAnswerClasses}>
              {loading ? (
                <Loading padding='120px 0 0 0' />
              ) : (
                <React.Fragment>
                  <Card>
                    <div className={styles['card-wrapper']}>
                      <div className={styles['quick-answer-header']}>
                        <Icon type='Notes' variant='brand' />
                        <Text size='t1' bold secondary>
                          Resposta rápida
                        </Text>
                      </div>

                      <Divider light />

                      <div className={styles['tasks-wrapper']}>
                        <QuickTasks
                          answers={answers}
                          onClick={handleMultipleChoiceClick}
                          tasks={quickTasks.tasks}
                        />
                        <TaskFeedback
                          isFinished={isFinished()}
                          onClick={handleTasksSubmit}
                          isSubmitting={isSubmittingAnswer}
                          tasksRate={quickTasks.rate}
                        />
                      </div>
                    </div>
                  </Card>
                </React.Fragment>
              )}
            </div>
          </Wrapper>
        </React.Fragment>
      </div>
    )
  }
}

export default QuickAnswer
