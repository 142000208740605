import React from 'react'
import PropTypes from 'prop-types'

import { Card, Text } from '@plurall/elo-beta'

import styles from './Answer.module.scss'

const Answer = ({ answer, image, resolutionVideo, title = 'gabarito', isPurple }) => (
  <Card secondary>
    <div className={`${styles['answer-container']} answer-container`}>
      <Text
        size='t3'
        semibold
        dimmed={!isPurple}
        textTransform='uppercase'
        variant={isPurple && 'brand'}
      >
        {title}
      </Text>

      {answer && <div className={styles.feedback} dangerouslySetInnerHTML={{ __html: answer }} />}

      {image && <img src={image} alt='imagem da resposta' />}

      {resolutionVideo && (
        <div
          className={styles['resolution-video']}
          dangerouslySetInnerHTML={{ __html: resolutionVideo }}
        />
      )}
    </div>
  </Card>
)

Answer.propTypes = {
  answer: PropTypes.string,
  image: PropTypes.string,
  resolutionVideo: PropTypes.string,
  title: PropTypes.string,
  isPurple: PropTypes.bool,
}

export default Answer
