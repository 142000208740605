import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@plurall/elo-beta'

const ReceiptButton = ({ data: { title, url } }) => (
  <React.Fragment>
    <div style={{ marginTop: 20 }}>
      <Button onClick={() => window.open(url, '_blank')}>{title}</Button>
    </div>
  </React.Fragment>
)

ReceiptButton.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ReceiptButton
