import getSupportMaterialType from 'utils/getSupportMaterialType'

const trackOnWrongAnswer = ({ dismissButton, option, trackingParams }) => {
  const { key, type } = option
  const attemptNumber = dismissButton ? 'first' : 'second'

  const trackingEvent = helpOption => `question:popup:${helpOption}:${attemptNumber}:attempt`

  const { doubts, isExternalVideo, isTextDocument } = getSupportMaterialType({ key, type })

  if (isTextDocument) {
    window.PLURALL_TRACKER.track(trackingEvent('read'), trackingParams)
  }

  if (isExternalVideo) {
    window.PLURALL_TRACKER.track(trackingEvent('video'), trackingParams)
  }

  if (doubts) {
    window.PLURALL_TRACKER.track(trackingEvent('doubts'), trackingParams)
  }
}

const trackVideoToggle = ({ key, params }) => {
  const { isResolutionVideo } = getSupportMaterialType({ key })

  const trackingEvent = videoType => `question:video:${videoType}:click`

  let trackName = trackingEvent('external')

  if (isResolutionVideo) {
    trackName = trackingEvent('resolution')
  }

  window.PLURALL_TRACKER.track(trackName, params)
}

export { trackOnWrongAnswer, trackVideoToggle }
