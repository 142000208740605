import React from 'react'
import PropTypes from 'prop-types'

import { Text } from '@plurall/elo-beta'

import styles from './SubtaskTitle.module.scss'

const SubtaskTitle = ({ title }) => (
  <div className={styles['subtask-title']}>
    <Text secondary>{title}</Text>
  </div>
)

SubtaskTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

export default SubtaskTitle
