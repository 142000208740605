import classNames from 'classnames/bind'
import React from 'react'
import PropTypes from 'prop-types'

import { Button, Text } from '@plurall/elo-beta'

import styles from './TaskFeedback.module.scss'

const cx = classNames.bind(styles)

const TaskFeedback = ({ isFinished, isSubmitting, onClick, tasksRate }) => {
  const submitButtonClasses = cx('submit-button-holder', { submitting: isSubmitting })
  return (
    <div className={styles['feedback-holder']}>
      <div className={submitButtonClasses}>
        <Button disabled={isFinished} onClick={!isFinished ? onClick : null} fullWidth>
          <Text semibold textTransform='uppercase'>
            corrigir
          </Text>
        </Button>
      </div>
      {tasksRate && (
        <div className={styles.feedback}>
          <Text size='t1' variant='brand' alignment='center'>
            Seu desempenho
          </Text>
          <span>{tasksRate}</span>
        </div>
      )}
    </div>
  )
}

TaskFeedback.propTypes = {
  isFinished: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onClick: PropTypes.func,
  tasksRate: PropTypes.string,
}

export default TaskFeedback
