import PropTypes from 'prop-types'
import queryString from 'query-string'
import React from 'react'

import { getOauthClient } from 'utils/oauth'
import { setToken } from 'utils/token'

class LoginCallback extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  }

  state = {}

  componentDidMount() {
    const oauth = getOauthClient()
    const { location } = this.props
    const fullPath = `${location.pathname}${location.search}${location.hash}`

    oauth.token.getToken(fullPath).then(token => {
      setToken(token.accessToken)
      this.setState({
        redirect: true,
      })
    })
  }

  render() {
    const { redirect } = this.state
    const { location } = this.props
    if (redirect) {
      const search = queryString.parse(location.search)
      const href =
        search?.redirectTo && search?.redirectTo !== 'undefined' ? search.redirectTo : '/'
      window.location.href = href
    }
    return null
  }
}

export default LoginCallback
