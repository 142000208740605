import classNames from 'classnames/bind'
import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { Tabs, Text, theme, InputDropdown, Divider } from '@plurall/elo-beta'
import Client from 'utils/client'
import { getUserName } from 'utils/getUserName'
import { TitleContext } from 'utils/context'

import TaskList from 'App/views/TaskList'

import NoResults from 'App/components/ErrorMessage/ErrorMessage'
import SubHeader from 'App/components/SubHeader'
import Wrapper from 'App/components/Wrapper'
import Loading from 'App/components/Loading'

import MaterialCard from './components/MaterialCard'

import styles from './index.module.scss'

const cx = classNames.bind(styles)
const client = new Client()

const MaterialList = () => {
  const [loading, setLoading] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [noSearchResults, setNoSearchResults] = useState(false)
  const [noResults, setNoResults] = useState(false)
  const [error, setError] = useState(false)
  const [material, setMaterial] = useState()
  const [materialSearch, setMaterialSearch] = useState()
  const [openIndexTab, setOpenIndexTab] = useState(0)
  const [menuItens, setMenuItens] = useState()
  const [selectMenu, setSelectMenu] = useState(0)
  const [loadingNotebooks, setLoadingNotebooks] = useState(false)
  const [order, setOrder] = useState(null)
  const [showAllMaterialSearch, setShowAllMaterialSearch] = useState(false)

  const [notebooks, setNotebooks] = useState()

  const isMobile = useMediaQuery({
    query: theme.elo.breakpoints.mobile,
  })

  const buildMenuItens = contexts =>
    contexts.map((item, index) => ({
      id: index + 1,
      label: item.menu,
      checked: false,
    }))

  const buildOrderItens = [
    { id: 1, label: 'Ordem alfabética A-Z', checked: order === 1 || !order },
    { id: 2, label: 'Ordem alfabética Z-A', checked: order === 2 },
  ]

  const selectItemMenu = indexSelected => {
    const itemMenu = menuItens.map((item, index) => ({
      ...item,
      checked: index === indexSelected,
    }))
    setMenuItens(itemMenu)
  }

  useEffect(() => {
    const getMaterials = async () => {
      try {
        await client.getContextMaterials().then(result => {
          if (result) {
            const {
              data: {
                data: { contexts },
              },
            } = result
            setLoading(false)
            setNoResults(contexts.length === 0)
            setMaterial(contexts)
            setMenuItens([
              { id: 0, label: 'Acesso rápido', checked: true },
              ...buildMenuItens(contexts),
            ])
            setSelectMenu(0)
          }
        })
      } catch ({ message }) {
        setLoading(false)
        setError(true)
      }
    }
    getMaterials()
  }, [])

  const renderMaterialListAllContexts = context =>
    context.notebooks?.values?.map((value, index) => (
      <div className={styles.materialListCards}>
        <MaterialCard key={value.id} index={index} material={value} />
      </div>
    ))

  const orderNotebooks = values => {
    const newOrder = [...values].sort((a, b) => {
      if (order === 2) {
        return b.value >= a.value ? 1 : -1
      }
      return a.value >= b.value ? 1 : -1
    })
    return newOrder
  }

  const renderNotebooksCards = values => {
    const orderValues = order ? orderNotebooks(values) : values
    return orderValues.map((value, index) => (
      <div className={styles.materialListCards}>
        <MaterialCard key={value.id} index={index} material={value} />
      </div>
    ))
  }

  const getNotebooks = async context => {
    const { contextIds, menu } = context
    const hasNotebooks = notebooks && notebooks[menu]

    if (!hasNotebooks) {
      setLoadingNotebooks(true)
      await client.getMaterials(contextIds).then(result => {
        const {
          data: {
            data: { values },
          },
        } = result
        setLoadingNotebooks(false)
        const notebook = {}
        notebook[menu] = values
        setNotebooks({ ...notebooks, ...notebook })
      })
    }
  }

  const handleOrderDropdown = notebookOrder => setOrder(notebookOrder.id)

  const renderNotebooks = values => {
    if (values && values.length > 0) {
      return (
        <div>
          <div className={styles.notebookListTitle}>
            <div className={styles.filterOrderText}>
              <text>{`${values.length} cadernos ${!isMobile ? 'ordenados por' : ''}`}</text>
            </div>
            <div className={styles.filterOrderDropdown}>
              <InputDropdown
                dataTestId=''
                minimal
                items={buildOrderItens}
                onChange={handleOrderDropdown}
              />
            </div>
          </div>
          {renderNotebooksCards(values)}
        </div>
      )
    }
    return (
      <NoResults title='Ops! Ocorreu um erro. Caso o erro persista, entre em contato com o suporte.' />
    )
  }

  const handleSearchBar = text => {
    setSearchText(text.trim())
    setNoSearchResults(false)
    setShowAllMaterialSearch(false)
  }
  const formatSearchText = text => text.toUpperCase().replace(/[\u0300-\u036f]/g, '')

  const changeTabs = index => {
    if (isMobile) {
      getNotebooks(material[index])
      setSelectMenu(index + 1)
      selectItemMenu(index + 1)
      setOpenIndexTab(0)
    } else {
      setOpenIndexTab(index + 1)
      setSelectMenu(0)
      getNotebooks(material[index])
    }
  }

  const renderAllMaterialListContext = filteredMaterials =>
    filteredMaterials.map((context, index) => (
      <React.Fragment>
        <div className={styles.materialListTitle}>
          <div>
            <Text size='t1' semibold='true'>
              {context?.menu}
            </Text>
          </div>
          <div className={styles.showAllCards}>
            <Text
              size='t3'
              alignment='right'
              onClick={() => changeTabs(index)}
              icon='Chevron-right'
              noUnderline
              element='a'
            >
              Mostrar tudo
            </Text>
          </div>
        </div>
        {renderMaterialListAllContexts(context)}
      </React.Fragment>
    ))

  const renderNotebooksList = context => (
    <div>
      {loadingNotebooks ? (
        <div className={styles.loadingNotebboks}>
          <Loading logoMaxHeight='100px' logoMaxWidth='100px' opacity='0.5' />
        </div>
      ) : (
        notebooks && renderNotebooks(notebooks[context.menu])
      )}
    </div>
  )

  const renderTabList = filteredMaterials => {
    const tabs = []
    tabs.push(<div label='Acesso rápido'>{renderAllMaterialListContext(filteredMaterials)}</div>)
    return tabs.concat(
      filteredMaterials.map(context => (
        <div label={context.menu}>{renderNotebooksList(context)}</div>
      )),
    )
  }

  const onMenuSelectChanged = item => {
    if (item.id > 0) getNotebooks(material[item.id - 1])
    setSelectMenu(item.id)
    setOpenIndexTab(0)
  }

  const filteredContexts =
    searchText &&
    material
      ?.map(context =>
        context.notebooks.values.filter(item =>
          formatSearchText(item.value).includes(formatSearchText(searchText)),
        ),
      )
      .filter(item => item.length > 0)

  const filteredNotebooks = selectedOptionContext => {
    if (showAllMaterialSearch && notebooks && searchText) {
      return notebooks[material[selectedOptionContext - 1].menu].filter(item =>
        formatSearchText(item.value).includes(formatSearchText(searchText)),
      )
    }
    return notebooks && searchText && notebooks[material[selectedOptionContext - 1]]
  }

  const rebuildMenuItens = () => {
    if (isMobile && selectMenu && selectMenu > 0) {
      return menuItens.map((item, index) => ({
        ...item,
        checked: index === selectMenu,
      }))
    }
    return menuItens
  }

  useEffect(() => {
    const selectedOptionContext = [openIndexTab, selectMenu].find(item => item > 0)
    if (selectedOptionContext && searchText && searchText.length > 0) {
      const filteredMaterials = [
        ...notebooks[material[selectedOptionContext - 1].menu],
      ].filter(item => formatSearchText(item.value).includes(formatSearchText(searchText)))

      if (filteredMaterials && filteredMaterials?.length > 0) {
        const formatMaterialSearch = filteredMaterials.map(item => ({
          id: item.id,
          label: item.value,
          thumbnail: item.thumbnail,
          groupTitle: '',
          group: {
            id: '1',
            label: 'SUGESTÕES',
          },
        }))

        setMaterialSearch(formatMaterialSearch)
      }
    }
  }, [searchText, openIndexTab, selectMenu, material, notebooks])

  const handleShowAllSearchClick = () => {
    setShowAllMaterialSearch(true)
  }

  const renderMaterialList = () => {
    const selectedOptionContext = [openIndexTab, selectMenu].find(item => item > 0)

    const filteredMaterials = selectedOptionContext
      ? filteredNotebooks(selectedOptionContext)
      : filteredContexts

    if (filteredMaterials?.length === 0 && material?.length === 0) {
      setNoSearchResults(true)
    }

    if (noResults) {
      return <NoResults title='Oops! Ainda não temos nada para exibir aqui.' />
    }

    if (filteredMaterials && filteredMaterials.length > 0) {
      return (
        <div>
          <div className={styles.materialSearchText}>
            {searchText && searchText?.length > 0 && (
              <Text>Resultados para &quot; {searchText} &quot;</Text>
            )}
          </div>
          <div className={!isMobile && styles.materialSearchCards}>
            {selectedOptionContext
              ? renderNotebooks(filteredMaterials)
              : filteredMaterials.map(item => renderNotebooks(item))}
          </div>
        </div>
      )
    }

    if (isMobile) {
      return (
        <div>
          <InputDropdown dataTestId='' items={rebuildMenuItens()} onChange={onMenuSelectChanged} />
          <Divider className={styles.materialListDivider} />

          {selectMenu === 0
            ? renderAllMaterialListContext(material)
            : renderNotebooksList(material[selectMenu - 1])}
        </div>
      )
    }

    return (
      <Tabs
        baseLine
        openIndex={openIndexTab}
        className={styles.materialListTabs}
        onTabChange={(tab, index) => {
          setSelectMenu(0)
          setOpenIndexTab(index)
          if (index > 0) {
            getNotebooks(material[index - 1])
          }
        }}
      >
        {renderTabList(material)}
      </Tabs>
    )
  }

  const materialListClasses = cx(
    'material-list',
    { 'no-results': loading || noResults },
    { 'material-list-min-height': loading },
    { materialListTabs: !loading && !noResults && !isMobile },
  )

  return (
    <React.Fragment>
      <Route path='/material/:materialId' component={TaskList} />

      <Route
        exact
        path='/'
        render={() => (
          <div>
            <TitleContext.Consumer>
              {data => (
                <SubHeader
                  subTitle=''
                  title='Atividades'
                  user={getUserName(data.userProfile)}
                  onSearch={handleSearchBar}
                  isMaterialList
                  materialSearch={materialSearch}
                  onShowAllSearchClick={handleShowAllSearchClick}
                  tab={isMobile ? selectMenu : openIndexTab}
                />
              )}
            </TitleContext.Consumer>

            <div className={styles.materialListWrapper}>
              {error ? (
                <NoResults title='Ops! Ocorreu um erro. Caso o erro persista, entre em contato com o suporte.' />
              ) : (
                <Wrapper>
                  {noSearchResults ? (
                    <NoResults title='Nenhum resultado encontrado.' />
                  ) : (
                    <div className={materialListClasses}>
                      {loading ? (
                        <Loading logoMaxHeight='100px' logoMaxWidth='100px' opacity='0.5' />
                      ) : (
                        renderMaterialList()
                      )}
                    </div>
                  )}
                </Wrapper>
              )}
            </div>
          </div>
        )}
      />
    </React.Fragment>
  )
}

export default MaterialList
