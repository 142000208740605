import React from 'react'
import PropTypes from 'prop-types'

import { Text } from '@plurall/elo-beta'

import { CORRECT, RIGHT, WRONG } from 'App/constants/exerciseStatus'
import styles from './Option.module.css'

class Option extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    sequence: PropTypes.string.isRequired,
    bold: PropTypes.bool,
    optionClass: PropTypes.string,
    isWhite: PropTypes.bool,
    isPurple: PropTypes.bool,
    sendAnswerEnabled: PropTypes.bool,
    rightAnswerIcon: PropTypes.node,
    wrongAnswerIcon: PropTypes.node,
  }

  renderOptionSequence = () => {
    const {
      bold,
      optionClass,
      isWhite,
      isPurple,
      rightAnswerIcon,
      wrongAnswerIcon,
      sequence,
    } = this.props

    switch (optionClass) {
      case WRONG: {
        return wrongAnswerIcon
      }

      case RIGHT: {
        return rightAnswerIcon
      }

      case CORRECT: {
        return rightAnswerIcon
      }

      default: {
        return (
          <div className={isWhite ? styles.white : ''}>
            <Text
              textTransform='uppercase'
              bold={bold}
              dimmed={!isPurple}
              variant={isPurple && 'brand'}
            >
              {sequence}
            </Text>
          </div>
        )
      }
    }
  }

  render() {
    const { onClick, sequence, optionClass, sendAnswerEnabled } = this.props

    return (
      <span
        className={`${styles.option} ${styles[optionClass]}`}
        key={sequence}
        onClick={onClick}
        style={{ cursor: optionClass !== 'wrong' && sendAnswerEnabled && 'pointer' }}
        data-test-id='option'
      >
        {this.renderOptionSequence()}
      </span>
    )
  }
}

export default Option
