import React from 'react'
import PropTypes from 'prop-types'

import styles from './SubItems.module.scss'

const SubItems = ({ items }) => {
  const renderSubitems = text => (
    <li className={styles.subitem}>
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: text }} />
    </li>
  )

  return <ul className={styles.subitems}>{items.map(text => renderSubitems(text))}</ul>
}

SubItems.propTypes = {
  items: PropTypes.array.isRequired,
}

export default SubItems
