import classNames from 'classnames/bind'
import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import queryString from 'query-string'

import { getUserName } from 'utils/getUserName'
import { TitleContext } from 'utils/context'

import Client from 'utils/client'
import { normalizeHelpOptions } from 'utils/normalizeHelpOptions'

import { Alert, Text } from '@plurall/elo-beta'
import SubHeader from 'App/components/SubHeader'
import Wrapper from 'App/components/Wrapper'
import Loading from 'App/components/Loading'

import { trackingEventNames } from 'App/constants/tracking'

import hacParams from 'utils/hacParams'

import { MULTIPLE_CHOICE, MULTIPLE_CHOICE_MORE, OPEN_RESPONSE } from 'App/constants/exerciseStatus'
import WrongAnswerButtons from './components/WrongAnswerButtons'
import Exercise from './components/Exercise'
import ExerciseSupport from './components/ExerciseSupport'
import Question from './components/Question'

import MaterialSupport from '../MaterialSupport'

import styles from './index.module.scss'

const cx = classNames.bind(styles)

class TaskExercise extends React.Component {
  static propTypes = {
    exercises: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    task: PropTypes.object,
    updateTask: PropTypes.func.isRequired,
  }

  state = { loading: true, messageLength: 0, userAnswer: [] }

  async componentDidMount() {
    this.setCurrentExercise()
    this.setState({
      progressInWords: this.props.task.progressInWords,
    })
  }

  componentDidUpdate(prevProps) {
    const shouldUpdateExercise =
      prevProps.exercises !== this.props.exercises ||
      prevProps.match.params.exerciseId !== this.props.match.params.exerciseId

    if (shouldUpdateExercise) {
      this.updateCurrentExercise()
    }
  }

  onPaginate = current => {
    if (this.state.isSubmittingAnswer) {
      return
    }

    const {
      props: {
        history,
        location: { pathname },
        match: {
          params: { exerciseId },
        },
      },
    } = this

    this.setState({ current, loading: true }, async () => {
      const nextExercise = await this.getNextExercise()
      this.setState({ ...nextExercise })
    })

    const { queryParameters } = hacParams()

    history.push({
      pathname: pathname.replace(
        `${exerciseId}`,
        `${this.getExercisesGroup().subtasks[current].id}`,
      ),
      search: queryParameters,
    })
  }

  onPopupDismiss = () => {
    this.setState({
      isConfirmationPopupOpen: false,
      isWarningPopupOpen: false,
    })
  }

  onSubmitConfirmation = () => {
    this.onPopupDismiss()
    this.submitAnswer()
    this.setState({
      message: '',
      messageLength: 0,
    })
  }

  onFileChange = file => {
    if (file.length !== 0) {
      this.setState({ file: file[0].src })
    } else {
      this.setState({ file: null })
    }
  }

  onChange = message => {
    const { questionId } = this.state

    this.setState({
      message: {
        ...this.state.message,
        [questionId]: message,
      },
      messageLength: message.trim().length,
    })
  }

  onMaterialClick = url => {
    this.setState(
      {
        exercise: { ...this.state.exercise, userMessage: null },
        url,
      },
      () => {
        if (this.state.userMessage) {
          this.unlockQuestion().then(this.props.updateTask)
        }
      },
    )
  }

  onSubmitButtonClick = () => {
    const { messageLength, file } = this.state

    if (messageLength >= 20 || file) {
      this.setState({
        isConfirmationPopupOpen: true,
      })
    } else {
      this.setState({
        isWarningPopupOpen: true,
      })
    }
  }

  onQuestionAnswered = async ({ progressInWords, userMessage }) => {
    const {
      answers,
      attemptsInWords,
      helpOptions,
      sendAnswerEnabled,
      taskType,
    } = await this.getExercise()

    const saveMessage = taskType === OPEN_RESPONSE ? '' : this.state.message
    this.setState(
      {
        answers,
        attemptsInWords,
        exercise: { ...this.state.exercise, userMessage },
        helpOptions,
        isSubmittingAnswer: false,
        file: null,
        loading: false,
        message: saveMessage,
        progressInWords,
        sendAnswerEnabled,
        taskType,
      },
      () => {
        if (!this.state.exercise.userMessage) {
          window.PLURALL_TRACKER.track(trackingEventNames.taskExercise.questionAnswerSubmit)
          this.props.updateTask()
          this.props.onListUpdated()
        } else {
          window.PLURALL_TRACKER.track(trackingEventNames.taskExercise.questionPopup)
        }
      },
    )
  }

  setQuestionId = id => {
    this.setState({ questionId: id })
  }

  getExercise = async () => {
    const exercise = this.findCurrentExercise()

    const {
      data: {
        data: { answers, attemptsInWords, links: helpOptions, sendAnswerEnabled, taskType },
      },
    } = await this.client.getExercise(exercise.apiUrl)

    return {
      answers,
      attemptsInWords,
      exercise,
      helpOptions,
      sendAnswerEnabled,
      taskType,
    }
  }

  getExercisesGroup = () => {
    const exercises = this.props.task.subtaskGroups.filter(group => group.layoutType === 'grid')

    const [group] = exercises
      .map((currentTask, index, exerciseList) =>
        this.findCurrentExercise(currentTask) ? exerciseList[index] : undefined,
      )
      .filter(item => !!item)

    return group
  }

  getNextExercise = async () => {
    const {
      answers,
      attemptsInWords,
      exercise,
      helpOptions,
      sendAnswerEnabled,
      taskType,
    } = await this.getExercise()

    const {
      data: { question },
    } = await this.client.getQuestion(exercise.questionUrl)

    return {
      answers,
      attemptsInWords,
      exercise,
      hasHelpOptions: helpOptions.length > 0,
      helpOptions,
      loading: false,
      question,
      sendAnswerEnabled,
      taskType,
    }
  }

  setCurrentExercise = async () => {
    this.taskGroup = this.getExercisesGroup()
    this.exerciseIds = this.taskGroup.subtasks.map(({ id }) => id)

    const nextExercise = await this.getNextExercise()

    this.setState({
      ...nextExercise,
      current: this.exerciseIds.indexOf(+this.props.match.params.exerciseId),
      total: this.taskGroup.subtasks.length,
    })
  }

  updateCurrentExercise() {
    this.setState({ loading: true }, () => {
      this.setCurrentExercise()
    })
  }

  joinAnswers = nextAnswer => {
    const { userAnswer } = this.state
    const newUserAnswers = [...userAnswer]

    const isSelected = userAnswer.includes(nextAnswer)
    if (isSelected) {
      newUserAnswers.splice(userAnswer.indexOf(nextAnswer), 1)
    } else {
      newUserAnswers.push(nextAnswer)
    }

    this.setState({
      userAnswer: newUserAnswers.sort(),
    })
  }

  unlockQuestion = async () => {
    await this.client.unlockQuestion({
      exerciseId: this.props.match.params.exerciseId,
      taskId: this.props.match.params.taskId,
    })
  }

  submitAnswer = (sequence, questionType) => {
    const {
      props: {
        match: {
          params: { exerciseId, taskId },
        },
      },
      state: { file, isSubmittingAnswer, message, questionId },
    } = this

    if (isSubmittingAnswer) {
      return null
    }

    const paramsRequest =
      questionType === MULTIPLE_CHOICE || questionType === MULTIPLE_CHOICE_MORE
        ? { exerciseId, sequence, taskId }
        : { exerciseId, file, message: message && message[questionId], taskId }

    this.setState(
      {
        isSubmittingAnswer: true,
        userAnswer: [],
      },
      async () => {
        const {
          data: {
            data: { progressInWords, userMessage },
          },
        } = await this.client.submitAnswer(paramsRequest)

        this.onQuestionAnswered({ progressInWords, userMessage })
      },
    )

    return null
  }

  findCurrentExercise = (taskGroup = this.taskGroup) =>
    taskGroup.subtasks.find(({ id }) => id === +this.props.match.params.exerciseId)

  client = new Client()

  renderExercise = () => {
    const {
      onChange,
      onFileChange,
      onMaterialClick,
      onPaginate,
      onPopupDismiss,
      onSubmitConfirmation,
      onSubmitButtonClick,
      submitAnswer,
      setQuestionId,
      joinAnswers,
      props: { task },
      state: {
        answers,
        attemptsInWords,
        current,
        exercise,
        file,
        hasHelpOptions,
        helpOptions,
        isConfirmationPopupOpen,
        isWarningPopupOpen,
        isSubmittingAnswer,
        message,
        messageLength,
        progressInWords,
        question,
        sendAnswerEnabled,
        taskType,
        total,
        userAnswer,
      },
    } = this

    const showExerciseHeader = true

    const supportLinks = normalizeHelpOptions(helpOptions)
    const dismissButton = exercise?.userMessage?.buttons.find(({ action }) => action === 'close')

    return (
      <React.Fragment>
        <Exercise
          current={current}
          hasHelpOptions={hasHelpOptions}
          onPaginate={onPaginate}
          progressInWords={progressInWords}
          showExerciseHeader={showExerciseHeader}
          task={task}
          total={total}
        >
          {exercise.userMessage && (
            <Alert
              variant='attention'
              primaryButtonText={dismissButton?.title}
              onPrimaryClick={onMaterialClick}
              primaryButtonDisabled={!dismissButton?.title}
              title={exercise.userMessage.title}
            >
              <div className={styles.text}>
                <Text secondary>{exercise.userMessage.text}</Text>
              </div>
              <WrongAnswerButtons
                helpOptions={supportLinks}
                onClick={onMaterialClick}
                dismissButton={dismissButton}
                taskName={task.name}
                userMessage={exercise.userMessage}
              />
            </Alert>
          )}
          {isWarningPopupOpen && (
            <Alert
              title='Aviso'
              variant='attention'
              primaryButtonText='Continuar'
              onPrimaryClick={onPopupDismiss}
            >
              <Text size='t3' dimmed>
                A resposta deve conter no mínimo 20 caracteres.
              </Text>
            </Alert>
          )}
          {isConfirmationPopupOpen && (
            <Alert
              title='Confirmar envio?'
              variant='attention'
              primaryButtonText='Confirmar'
              onPrimaryClick={onSubmitConfirmation}
              secondaryButtonText='Agora não'
              onSecondaryClick={onPopupDismiss}
            >
              <Text secondary>Após o envio, sua resposta não poderá ser editada.</Text>
            </Alert>
          )}

          <Question
            answers={answers}
            attemptsInWords={attemptsInWords}
            exercise={exercise}
            file={file}
            isSubmittingAnswer={isSubmittingAnswer}
            message={message}
            messageLength={messageLength}
            onChange={onChange}
            onFileChange={onFileChange}
            onSubmitButtonClick={onSubmitButtonClick}
            submitAnswer={submitAnswer}
            question={question}
            sendAnswerEnabled={sendAnswerEnabled} // Giovanni, se der merda em algo de responder multipla escolha, volta aqui
            task={task}
            taskType={taskType}
            answerType={exercise.answerType}
            setQuestionId={setQuestionId}
            userAnswer={userAnswer}
            joinAnswers={joinAnswers}
          />
        </Exercise>

        {hasHelpOptions && (
          <ExerciseSupport
            helpOptions={supportLinks}
            onMaterialClick={onMaterialClick}
            task={task}
          />
        )}
      </React.Fragment>
    )
  }

  render() {
    const {
      props: {
        location: { search },
        match: { params, path },
        task,
      },
      state: { exercise, loading, url },
    } = this

    const { queryParameters } = hacParams()
    const basePath = `/material/${params.materialId}/aula/${params.lessonId}`
    const { resposta_rapida: respostaRapida } = queryString.parse(search)
    const goBack = respostaRapida
      ? `${basePath}/resposta-rapida/${queryParameters}`
      : `${basePath}/tarefa/${params.taskId}/${queryParameters}`
    const contentHolderClasses = cx('content-holder', { 'content-holder-min-height': loading })

    return (
      <React.Fragment>
        <Route
          path={`${path}${path.endsWith('/') ? '' : '/'}material-de-apoio`}
          render={props => {
            if (!url) {
              return (
                <Redirect
                  to={`${basePath}/tarefa/${params.taskId}/exercicio/${params.exerciseId}/${queryParameters}`}
                />
              )
            }

            return <MaterialSupport {...props} exercise={exercise} task={task} url={url} />
          }}
        />

        <Route
          exact
          path='/material/:materialId/aula/:lessonId/tarefa/:taskId/exercicio/:exerciseId/'
          render={() => (
            <div id='exercise'>
              <TitleContext.Consumer>
                {data => (
                  <SubHeader
                    title={
                      exercise && task && `${task.name} - Exercício ${exercise && exercise.title}`
                    }
                    subTitle={
                      data.taskListTitle &&
                      `${data.taskListTitle} / ${task.subtitle} ${
                        task.title ? `/ ${task.title}` : ''
                      }`
                    }
                    user={getUserName(data.userProfile)}
                    backTo={goBack}
                  />
                )}
              </TitleContext.Consumer>

              <Wrapper padding='20px 0 0 0'>
                <div className={contentHolderClasses}>
                  {loading ? <Loading padding='100px 0 0 0' /> : this.renderExercise()}
                </div>
              </Wrapper>
            </div>
          )}
        />
      </React.Fragment>
    )
  }
}

export default TaskExercise
