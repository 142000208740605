import React from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, Icon, Input, Text } from '@plurall/elo-beta'

import Client from 'utils/client'
import hacParams from 'utils/hacParams'
import { trackingEventNames } from 'App/constants/tracking'

import styles from './FinishButton.module.css'

class FinishButton extends React.Component {
  state = {
    disabledConfirmation: true,
    error: null,
    finishButtonPopup: 'Finalizar',
    loading: false,
    text: this.props.data.text,
    tasks: this.props.tasks,
    inputTextKey: '',
  }

  onChange = e => {
    this.setState({ inputTextKey: e })

    if (e.toLowerCase() === this.props.data.key.toLowerCase()) {
      this.setState({ disabledConfirmation: false })
    } else {
      this.setState({ disabledConfirmation: true })
    }
  }

  finishFailure = message => {
    this.setState({
      loading: false,
      finishButtonPopup: 'Finalizar',
      disabledConfirmation: true,
      error: message || 'Tivemos um problema, tente novamente.',
    })
  }

  send = () => {
    if (this.state.loading) {
      return
    }

    const { data, onListUpdated } = this.props
    this.setState({
      disabledConfirmation: true,
      loading: true,
    })

    this.client
      .finishButton({ url: data.url, key: this.state.inputTextKey })
      .then(
        props => {
          if (props.data?.result === 'success') {
            window.PLURALL_TRACKER.track(
              trackingEventNames.finishButton.eoEndTestConfirmationClickSuccess,
              this.trackingParams(),
            )
            setTimeout(() => {
              this.setState({
                confirmation: false,
                disabledConfirmation: false,
                loading: false,
                text: props.data?.data?.msg || 'Entregue!!!',
              })
              setTimeout(() => {
                this.setState({ hide: true })
              }, 2500)
            }, 2500)
            onListUpdated()
          } else {
            this.trackingClickFailure()
            this.finishFailure(props.error?.response?.data?.message)
          }
        },
        error => {
          this.trackingClickFailure()
          this.finishFailure(error?.response?.data?.message)
        },
      )
      .catch(error => {
        this.trackingClickFailure()
        this.finishFailure(error?.response?.data?.message)
      })
  }

  trackingClickFailure = () =>
    window.PLURALL_TRACKER.track(
      trackingEventNames.finishButton.eoEndTestConfirmationClickFailure,
      this.trackingParams(),
    )

  closeModal = () => {
    this.setState({
      confirmation: false,
      error: null,
      loading: false,
      disabledConfirmation: true,
    })
  }

  client = new Client()

  inputConfirmation = () => (
    <React.Fragment>
      <div className={styles.input}>
        <Input onChange={this.onChange} />
        {this.state.error && <div className={styles.error}>{this.state.error}</div>}
      </div>
    </React.Fragment>
  )

  trackingParams = () => {
    const { tasks } = this.state

    return { task_workflow_ids: tasks.map(task => task.id) }
  }

  render() {
    const { filter_context: filterContext } = hacParams()
    const { confirmation, hide, text } = this.state
    const title = (
      <div className={styles.title}>
        <Icon type='Exclamation-circle' variant='informative' size='big'>
          {this.props.data.key}
        </Icon>
        Tem certeza que deseja finalizar {filterContext === '18' ? 'o simulado' : 'a prova'}?
      </div>
    )

    const textAnswerPopup = (
      <span>
        Você não poderá mais responder.
        <br />
        <br />
        Digite{' '}
        <Text textTransform='uppercase' semibold variant='negative' secondary element='span'>
          {this.props.data.key}
        </Text>{' '}
        para confirmar:
      </span>
    )

    return (
      <React.Fragment>
        {confirmation && (
          <Dialog
            style={{ maxWidth: '800px' }}
            onClose={this.closeModal}
            title={title}
            primaryButtonText={this.state.finishButtonPopup}
            primaryButtonLoading={this.state.loading}
            primaryButtonDisabled={this.state.disabledConfirmation}
            onPrimaryClick={this.send}
            closePrimaryClick={false}
            secondaryButtonText='Cancelar'
            onSecondaryClick={this.closeModal}
          >
            <div className={styles.dialog}>
              <Text secondary>{textAnswerPopup}</Text>
              {this.inputConfirmation()}
            </div>
          </Dialog>
        )}
        {!hide && (
          <React.Fragment>
            <div style={{ marginTop: 20 }}>
              <Button
                onClick={() => {
                  this.setState({ confirmation: true })
                  window.PLURALL_TRACKER.track(
                    trackingEventNames.finishButton.eoEndTestClick,
                    this.trackingParams(),
                  )
                }}
              >
                {text}
              </Button>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

FinishButton.propTypes = {
  data: PropTypes.object.isRequired,
  tasks: PropTypes.array,
  onListUpdated: PropTypes.func,
}

export default FinishButton
