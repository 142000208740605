import React from 'react'
import PropTypes from 'prop-types'
import { Link, Route } from 'react-router-dom'

import { Text, Button } from '@plurall/elo-beta'

import GridLayout from 'App/components/GridLayout'
import Timer from 'App/components/Timer'
import FinishButton from 'App/components/FinishButton'
import ReceiptButton from 'App/components/ReceiptButton'

import { trackingEventNames } from 'App/constants/tracking'

import ProgressGroup from './ProgressGroup'

import styles from './TaskGroup.module.scss'

const TaskCardGroup = ({
  discipline,
  index,
  lesson,
  lessonId,
  quickAnswer,
  tasks,
  video,
  endDate,
  finishButton,
  receiptButton,
  totalTasks,
  onListUpdated,
}) => (
  <Route
    render={({ location: { pathname } }) => (
      <div className={styles['task-group']}>
        {endDate && <Timer endDate={endDate} />}
        <header className={styles['task-header']}>
          <div className={styles['task-information-wrapper']}>
            {discipline && (
              <span id={`discipline-${index + 1}`}>
                <Text size='t3' secondary semibold>
                  {discipline}
                </Text>
              </span>
            )}

            {lesson && !endDate && (
              <div className={styles['task-information-lesson']}>
                <Text size='t1' secondary bold>
                  {lesson}
                </Text>
              </div>
            )}
          </div>

          {quickAnswer && (
            <div
              className={styles['quick-answer-wrapper']}
              onClick={() =>
                window.PLURALL_TRACKER.track(trackingEventNames.taskGroup.quickAnswerClick)
              }
            >
              <Link
                to={`${pathname}${
                  pathname.endsWith('/') ? '' : '/'
                }aula/${lessonId}/resposta-rapida?total=${totalTasks}`}
              >
                <Button icon='Notes' iconDirection='left' secondary>
                  <span className={styles['quick-answer']}>
                    <Text size='t3' variant='brand' alignment='center' element='span'>
                      Resposta rápida
                    </Text>
                  </span>
                </Button>
              </Link>
            </div>
          )}
        </header>
        <GridLayout
          media={video}
          onMediaClick={() =>
            window.PLURALL_TRACKER.track(trackingEventNames.taskGroup.askListVideoClick)
          }
        >
          {tasks.map((task, taskIndex) => (
            <ProgressGroup
              index={taskIndex}
              key={task.id}
              lessonId={lessonId}
              video={video}
              task={task}
            />
          ))}
        </GridLayout>
        {finishButton && (
          <FinishButton data={finishButton} tasks={tasks} onListUpdated={onListUpdated} />
        )}
        {receiptButton && <ReceiptButton data={receiptButton} />}
      </div>
    )}
  />
)

TaskCardGroup.propTypes = {
  discipline: PropTypes.string,
  index: PropTypes.number.isRequired,
  lesson: PropTypes.string.isRequired,
  lessonId: PropTypes.string,
  quickAnswer: PropTypes.bool,
  tasks: PropTypes.array,
  video: PropTypes.object,
  endDate: PropTypes.number,
  finishButton: PropTypes.object,
  receiptButton: PropTypes.object,
  totalTasks: PropTypes.number,
  onListUpdated: PropTypes.func,
}

export default TaskCardGroup
