import classNames from 'classnames/bind'
import React from 'react'
import PropTypes from 'prop-types'
import { Link, Route } from 'react-router-dom'

import { Card, Text } from '@plurall/elo-beta'

import hacParams from 'utils/hacParams'

import { ANSWERED, CORRECT, FINISHED } from 'App/constants/exerciseStatus'

import styles from './MediaTask.module.scss'

const cx = classNames.bind(styles)

const MediaTask = ({ onTaskClick, subtasks, video }) => (
  <Route
    render={({ location: { pathname } }) => {
      const mediaTaskClasses = cx({ 'media-task': !video }, { 'media-task-with-video': video })
      return (
        <section className={mediaTaskClasses}>
          {subtasks.map(({ status, taskType, title: subtaskTitle, url, userAnswer }) => {
            const isTaskDone = status === FINISHED || status === CORRECT
            const isLessonAnswered = status === ANSWERED

            const type = {
              read: 'leitura',
              video: 'video',
            }

            const getTaskStripColor = () => {
              if (isTaskDone) {
                return 'var(--color-mint30)'
              }

              if (isLessonAnswered) {
                return 'var(--color-purple40)'
              }

              return 'var(--color-shade30)'
            }

            const { queryParameters } = hacParams()

            return (
              <Link
                to={`${pathname}${pathname.endsWith('/') ? '' : '/'}${
                  type[taskType]
                }/${queryParameters}`}
                key={subtaskTitle}
              >
                <div
                  className={styles['subtask-title']}
                  onClick={() => onTaskClick({ status, url })}
                >
                  <Card interactive>
                    <div className={styles['card-wrapper']}>
                      <Text secondary>{subtaskTitle}</Text>
                      <div
                        className={styles['task-status-holder']}
                        style={{
                          background: getTaskStripColor(),
                        }}
                      >
                        {userAnswer && (
                          <div className={styles['user-answer']}>
                            <Text size='t3' secondary>
                              {userAnswer}
                            </Text>
                          </div>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
              </Link>
            )
          })}
        </section>
      )
    }}
  />
)

MediaTask.propTypes = {
  onTaskClick: PropTypes.func.isRequired,
  subtasks: PropTypes.array.isRequired,
  video: PropTypes.object,
}

export default MediaTask
