import React from 'react'
import PropTypes from 'prop-types'

import { Text } from '@plurall/elo-beta'

import SupportCard from './SupportCard'

import styles from './ExerciseSupport.module.scss'

const renderSupportTitle = title => (
  <div className={styles['title-wrapper']}>
    <Text size='t3' semibold secondary>
      {title}
    </Text>
  </div>
)

const ExerciseSupport = ({ helpOptions: { doubtsLink, mediaLinks }, onMaterialClick, task }) => (
  <section className={styles['support-sidebar']}>
    {doubtsLink && (
      <div className={styles['support-wrapper']}>
        {renderSupportTitle('Gostaria de tirar uma dúvida?')}

        <SupportCard onClick={onMaterialClick} option={doubtsLink} taskName={task.name} />
      </div>
    )}

    {mediaLinks && (
      <div className={styles['support-wrapper']}>
        {renderSupportTitle('Material de apoio:')}

        {mediaLinks.map(option => (
          <SupportCard
            key={option.title}
            onClick={onMaterialClick}
            option={option}
            taskName={task.name}
            taskVideo={task.video}
          />
        ))}
      </div>
    )}
  </section>
)

const optionProps = {
  downloadUrl: PropTypes.string,
  key: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

ExerciseSupport.propTypes = {
  helpOptions: PropTypes.arrayOf(PropTypes.shape(optionProps)).isRequired,
  onMaterialClick: PropTypes.func.isRequired,
  task: PropTypes.shape({
    name: PropTypes.string.isRequired,
    video: PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      thumbnail: PropTypes.string,
    }),
  }).isRequired,
}

export default ExerciseSupport
export { optionProps }
