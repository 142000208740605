import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Button } from '@plurall/elo-beta'

import {
  ESSAY,
  MULTIPLE_CHOICE,
  NULLIFIED,
  OPEN_RESPONSE,
  OPEN_RESPONSE_SUBITEMS,
} from 'App/constants/exerciseStatus'
import MultipleChoice from './MultipleChoice'

import OpenResponse from './OpenResponse'
import SubItems from './SubItems'
import Tag from './Tag'

import styles from './Question.module.scss'

const Question = ({
  answers,
  answerType,
  attemptsInWords,
  exercise,
  file,
  isSubmittingAnswer,
  message,
  messageLength,
  onChange,
  onFileChange,
  onSubmitButtonClick,
  question,
  sendAnswerEnabled,
  submitAnswer,
  taskType,
  setQuestionId,
  userAnswer,
  joinAnswers,
}) => {
  const officialAnswer = exercise.officialAnswer || question.answer
  const formatedQuestion = text => {
    const node = text.match(/^.*(?= <p)/g)
    return node ? { __html: text.replace(node, '') } : { __html: text }
  }

  useEffect(() => {
    setQuestionId(question.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const completeMessage = message ? message[question.id] : ''

  return (
    <div className={styles['question-container']} style={{ opacity: isSubmittingAnswer && '0.4' }}>
      {attemptsInWords && <Tag className='attempts' text={attemptsInWords} />}

      {exercise.status === NULLIFIED && <Tag className='nullified' text='questão anulada' />}

      <div
        className={styles['question-text']}
        dangerouslySetInnerHTML={formatedQuestion(question.text)}
      />

      {taskType === MULTIPLE_CHOICE && (
        <MultipleChoice
          answers={answers}
          officialAnswer={officialAnswer}
          question={question}
          sendAnswerEnabled={sendAnswerEnabled}
          submitAnswer={submitAnswer}
          questionType={question.kind}
          userAnswer={userAnswer}
          joinAnswers={joinAnswers}
        />
      )}

      {taskType === OPEN_RESPONSE && (
        <div data-test-id='open-response'>
          {question.kind === OPEN_RESPONSE_SUBITEMS && <SubItems items={question?.subitems} />}

          <OpenResponse
            answers={answers}
            answerType={answerType}
            file={file}
            message={completeMessage}
            messageLength={messageLength}
            officialAnswer={officialAnswer}
            onChange={onChange}
            onFileChange={onFileChange}
            resolutionVideo={question.resolutionVideo}
            sendAnswerEnabled={sendAnswerEnabled}
            onSubmitButtonClick={onSubmitButtonClick}
            questionType={question.kind}
          />
        </div>
      )}

      {taskType === ESSAY && sendAnswerEnabled && (
        <div>
          <Button onClick={() => submitAnswer(new Date().getTime())}>Entreguei</Button>
        </div>
      )}
    </div>
  )
}

Question.propTypes = {
  answers: PropTypes.array,
  answerType: PropTypes.string,
  attemptsInWords: PropTypes.string,
  exercise: PropTypes.object.isRequired,
  file: PropTypes.string,
  isSubmittingAnswer: PropTypes.bool,
  message: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFileChange: PropTypes.func.isRequired,
  onSubmitButtonClick: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  sendAnswerEnabled: PropTypes.bool,
  submitAnswer: PropTypes.func.isRequired,
  taskType: PropTypes.string.isRequired,
}

export default Question
